.c-credit-toggle {
  position: relative;

  input:checked + & {
    background: black;
    color: white;
  }
}

.c-credit-toggle__control {
  margin-left: .579em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:active,
  &:focus {
    outline: none;
  }
}

.c-credit-toggle__label {
  align-items: center;
  background: $grey-lighter;
  display: flex;
  padding: .579em .579em .579em 2.074em;
  width: 100%;

  .c-credit-toggle__control:checked + & {
    background: $grey-900;
    color: $grey-300
  }
}

.c-credit-toggle__label-credits,
.c-credit-toggle__label-pricing {
  display: flex;
  flex-direction: column;
}

.c-credit-toggle__label-credits {
  width: 8rem;
}

.c-credit-toggle__label-pricing {
  justify-content: space-between;
  width: 10rem;
}

.c-credit-toggle__label-credits {
  order: -1;
}

.c-credit-toggle__label-tagline {
  flex: 1;
  padding: 0 .833em;
  text-align: center;
  text-transform: uppercase;
}

@include mq($from: md) {
  .c-credit-toggle__label-credits,
  .c-credit-toggle__label-pricing {
    align-items: center;
    flex-direction: row;
  }
}
