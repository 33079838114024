.c-Filters {
  background: #fff;
  box-shadow: 0 2px 2px rgba(0,0,0,.1);
  position: relative;
}

.c-Filters-toggle {
  display: none;
}

.c-Filters-header {
  align-items: center;
  border-bottom: 1px solid $grey-lighter;
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  position: relative;
  user-select: none;

  .c-Filters-toggle:checked ~ &{
    .c-Hamburger-fixins {
      background: transparent;
    }
    .c-Hamburger-fixins::before {
      transform: rotateZ(39deg);
      transform-origin: left top;
    }

    .c-Hamburger-fixins::after {
      transform: rotateZ(-39deg);
      transform-origin: left center;
    }
  }
}

.c-Filters-body {
  display: none;
  padding: 1em;

  .c-Filters-toggle:checked ~ & {
    display: block;
  }
}

.c-Filters-title {
  display: inline-block;
  flex: 1;
  font-size: 1.44em;
  font-weight: $global-normal-weight;
  margin: 0;
  padding: 0 4px;
  vertical-align: middle;
}

.c-Filters-stats {
  float: right;
  font-size: .833em;
}





.c-Hamburger {
  display: inline-block;
  padding: 6px 4px;
  user-select: none;
}

.c-Hamburger-fixins {
  background: #000;
  display: block;
  height: 1px;
  position: relative;
  transition: all .7s ease;
  width: 16px;

  &::after,
  &::before {
    background: #000;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    transition: all .7s ease;
    width: 100%;
  }

  &::before {
    top: -5px;
  }

  &::after {
    top: 5px;
  }
}
