.u-column-count-2 {
  column-count:2
}

.u-break-inside-avoid {
  page-break-inside:avoid;
  break-inside:avoid;
}

@media (min-width:30em) {
  .sm\:u-column-count-2 { column-count:2; }
}
