/**
 * Column spanning
 */
.u-colspan2 {
  grid-column: span 2 !important;
}

.u-colspan3 {
  grid-column: span 3 !important;
}

.u-colspan4 {
  grid-column: span 4 !important;
}

.u-colspan5 {
  grid-column: span 5 !important;
}

.u-colspan6 {
  grid-column: span 6 !important;
}

.u-colspan12 {
  grid-column: span 12 !important;
}


/**
 * Row spanning
 */
.u-rowspan2 {
  grid-row: span 2 !important;
}

.u-rowspan3 {
  grid-row: span 3 !important;
}

.u-rowspan4 {
  grid-row: span 4 !important;
}

.u-rowspan5 {
  grid-row: span 5 !important;
}

.u-rowspan6 {
  grid-row: span 6 !important;
}


/* Small breakpoint */
@include mq($from: sm) {
  /**
   * Column spanning
   */
  .u-sm-colspan2 {
    grid-column: span 2 !important;
  }

  .u-sm-colspan3 {
    grid-column: span 3 !important;
  }

  .u-sm-colspan4 {
    grid-column: span 4 !important;
  }

  .u-sm-colspan5 {
    grid-column: span 5 !important;
  }

  .u-sm-colspan6 {
    grid-column: span 6 !important;
  }


  /**
   * Row spanning
   */
  .u-sm-rowspan2 {
    grid-row: span 2 !important;
  }

  .u-sm-rowspan3 {
    grid-row: span 3 !important;
  }

  .u-sm-rowspan4 {
    grid-row: span 4 !important;
  }

  .u-sm-rowspan5 {
    grid-row: span 5 !important;
  }

  .u-sm-rowspan6 {
    grid-row: span 6 !important;
  }
}


/* Medium breakpoint */
@include mq($from: md) {
  /**
   * Column spanning
   */
  .u-md-colspan2 {
    grid-column: span 2 !important;
  }

  .u-md-colspan3 {
    grid-column: span 3 !important;
  }

  .u-md-colspan4 {
    grid-column: span 4 !important;
  }

  .u-md-colspan5 {
    grid-column: span 5 !important;
  }

  .u-md-colspan6 {
    grid-column: span 6 !important;
  }


  /**
   * Row spanning
   */
  .u-md-rowspan2 {
    grid-row: span 2 !important;
  }

  .u-md-rowspan3 {
    grid-row: span 3 !important;
  }

  .u-md-rowspan4 {
    grid-row: span 4 !important;
  }

  .u-md-rowspan5 {
    grid-row: span 5 !important;
  }

  .u-md-rowspan6 {
    grid-row: span 6 !important;
  }
}


/* Large breakpoint */
@include mq($from: lg) {
  /**
   * Column spanning
   */
  .u-lg-colspan2 {
    grid-column: span 2 !important;
  }

  .u-lg-colspan3 {
    grid-column: span 3 !important;
  }

  .u-lg-colspan4 {
    grid-column: span 4 !important;
  }

  .u-lg-colspan5 {
    grid-column: span 5 !important;
  }

  .u-lg-colspan6 {
    grid-column: span 6 !important;
  }


  /**
   * Row spanning
   */
  .u-lg-rowspan2 {
    grid-row: span 2 !important;
  }

  .u-lg-rowspan3 {
    grid-row: span 3 !important;
  }

  .u-lg-rowspan4 {
    grid-row: span 4 !important;
  }

  .u-lg-rowspan5 {
    grid-row: span 5 !important;
  }

  .u-lg-rowspan6 {
    grid-row: span 6 !important;
  }
}
