.c-AsideCard {
  background: #fff;
  border: 1px solid transparent;
  margin-bottom: $global-spacing-unit;
  position: relative;
}

.c-AsideCard-title {
  margin: 0;
  padding: $global-spacing-unit;
}

.c-AsideCard-body {
  margin-bottom: $global-spacing-unit;
}

.c-AsideCard-inner {
  padding: 0 $global-spacing-unit;
}

/* Modifiers */

/* Grey background and borer */

.c-AsideCard--grey {
  background: $grey-lighter;
  border-color: $grey-light;
}

/* Blank background and border */
.c-AsideCard--blank {
  background: transparent;
  border-color: transparent;
}

/* Small padding */
.c-AsideCard--sm {
  .c-AsideCard-title {
    padding: $global-spacing-unit-sm;
  }

  .c-AsideCard-inner {
    padding: 0 $global-spacing-unit-sm;
  }
}
