.c-header {
  display:flex;
  flex-direction:column;
  position: sticky;
  top: 0;
  z-index: 900;

  .has-hero & {
    position:sticky;
    top:0;
    width:100%;
    z-index:1;
  }
}

.c-header__inner {
  align-items: center;
  background: #fff;
  border-top: 1px solid $grey-lighter;
  display: flex;
  height: 55px;

  &:last-child {
    border-bottom: 1px solid $grey-lighter;
  }
}

.c-header__nav {
  display: none;

  a {
    text-decoration: none;
  }
}

.c-header__nav-action {
  color: $grey-darker;
  font-size: .833em;
  font-weight: 700;
  margin-left: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
}

.c-header__search {
  border-top: 1px solid $grey-lighter;

  .c-search-bar {
    border: none;
  }

  .c-header--toggle-search & {
    display: none;
    opacity: 0;
    transition: .3s;

    &.is-toggled {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
}

.c-header__buttons {
  align-items: center;
  display: flex;
  flex: 1;

  &:last-child {
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 1rem;
  }
}

.c-header__brand {
  align-self: flex-start;
}

.c-header__logo{
  align-items:center;
  background: #000;
  display:flex;
  height:66px;
  justify-content:center;
  margin:0 auto;
  position:relative;
  width:100px;
  z-index:1;

  .c-logo {
    max-height:100%;
  }

  .has-notification & {
    margin-top:-1px;
  }
}

@include mq($from: 800px) {


  .c-header__menu-toggle,
  .c-sidebar {
    display: none;
  }

  .c-header__buttons {
    flex: 0;
  }

  .c-header__brand {
    order: -1;
  }

  .c-header__logo {
    height:110px;
    width:150px;
  }

  /* make room for branding */
  .c-header__search {
    padding-left: 150px;

    .c-header--toggle-search & {
      display: block;
      opacity: 0;
      transition: .3s;
      visibility: hidden;

      &.is-toggled {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .c-header__nav {
    display: flex;
  }
}
