/**
 * <nav class="o-Breadcrumbs">
 *   <a class="o-Breadcrumbs-crumb">Divvy</a>
 *   <a class="o-Breadcrumbs-crumb">Collections</a>
 * </nav>
 */

.c-breadcrumbs {
  font-size: .833em;
  margin-bottom: $global-spacing-unit;
}

.c-breadcrumbs__crumb {
  display: inline-block;
  text-decoration: none;

  &::after {
    border-top: 2px solid $grey;
    border-right: 2px solid $grey;
    content: '';
    display: inline-block;
    height : 7px;
    margin: 0 12px;
    transform: rotate(45deg);
    width: 7px;
  }

  &:last-child::after {
    display: none;
  }
}
