.c-Pill {
  background: #fff;
  border-radius: 25px;
  display: block;
  padding: 4px 8px;
  text-decoration: none;

  .c-Close {
    color: inherit;
    font-size: 16px;
  }
}
