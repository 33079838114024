.c-logo {
  color: white;
  margin: 0;
}

.c-logo__tagline {
  visibility: hidden;
}

.c-logo__mark-top {
  fill: #a9dde4;
  fill: $teal-300;
}

.c-logo__mark-bottom {
  fill: #35c0cd;
  fill: $teal-500;
}

.c-logo__circle {
  fill: none;
  stroke: currentColor;
  stroke-width: 3px;
  stroke-meterlimit: 10px;
}

.c-logo--dark {
  color: #000;
}

@include mq($from: sm) {
  .c-logo__tagline {
    visibility: visible;
  }

  .c-logo__circle {
    stroke-width: 2px;
  }
}
