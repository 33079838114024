.c-variant-group {
  display: flex;
  margin: 0 -.5rem .5rem;
}

.c-variant-group__item {
  flex: 1;
  padding: .5rem;
  position: relative;
}

.c-variant-group__help {
  background: $grey-light;
  border-radius: 9999px;
  color: $grey-dark;
  display: block;
  font-size: 13px;
  height: 16px;
  margin-left: 50%;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: -23px;
  transform: translateX(-50%);
  width: 16px;
}

.c-variant-group__input {
  display: none;
}

.c-variant-group__label {
  background: #fff;
  border-radius: .25rem;
  color: currentColor;
  display: block;
  padding: 9px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  .c-variant-group__variant & {
    border: 1px solid $grey-lighter;
    box-shadow: 0 2px 4px rgba(0,0,0,.10);

    &:hover {
      border-color: $grey-light;
    }
  }

  :checked ~ & {
    background: #000;
    border-color: transparent;
    box-shadow: none;
    color: #fff;
  }

  :disabled + & {
    .c-variant-group__variant-title,
    .c-variant-group__variant-price {
      opacity: .25;
    }
  }
}

.c-variant-group__variant-title,
.c-variant-group__variant-price {
  display: block;
}

.c-variant-group__variant-title {
  font-size: .875rem;
}

.c-variant-group__variant-price {
  font-size: 1.125rem;
  font-weight: bold;
}

@include mq($from: sm) {
  .c-variant-group__variant-price {
    font-size: 1.25rem;
  }
}
