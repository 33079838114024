.c-FeatureSection {
  padding: $global-spacing-unit-lg 0;
}

.c-FeatureSection-headline,
.c-FeatureSection-message {
  display: inline-block;
  max-width: $global-content-width;
}

.c-FeatureSection-headline--markBlack {
  background: #000;
  box-shadow: -10px 0 0 #000, 10px 0 0 #000;
  color: #fff;
  display: inline;
  line-height: 1.44;
  left: 10px;
  padding: .279rem 0;
  position: relative;

  &::after {
    content: '';
    display: block;
  }
}

.c-FeatureSection-message {
  color: $grey-darker;
  font-size: 1.2em;
}

.c-FeatureSection-section {
  padding: $global-spacing-unit;

  > :last-child {
    margin-bottom: 0;
  }
}

.c-FeatureHeadline {
  font-size: 2.074em;
  font-weight: 300;

  [class*="blue"] & {
    color: #fff;
  }
}


@include mq($from: sm) {
  .c-FeatureSection-headline {
    font-size: 2.488em;
  }
}

@include mq($from: md) {
  .c-FeatureSection {
    padding: $global-spacing-unit-xl 0;
  }

  .c-FeatureSection-headline--markBlack {
    font-size: 2.986em;
  }
}

@include mq($from: lg) {
  .c-FeatureSection {
    padding: 7.43em 0;
  }
}
