$filters-background: $grey-darker;
$filters-color: #fff;
$search-bar-height: 3rem;
$search-bar-height-lg: 4rem;

@keyframes colorMotion {
  0% {
    transform: translate(0, -8px) rotate(-45deg);
    opacity: 0;
  }
  60% {
    transform: translate(0, 0) rotate(-45deg);
    opacity: 0.8;
  }
}

@keyframes colorMotion2 {
  0% {
    transform: translate(0, -8px) rotate(-45deg);
    opacity: 0;
  }
  60% {
    transform: translate(0, 0px) rotate(-45deg);
    opacity: 0.6;
  }
}

.c-search-bar {
  align-items: stretch;
  border: 1px solid $grey-lighter;
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  min-height: $search-bar-height;
  position: relative;
  width: 100%;

  /* Algolia Instant Search classes */
  .ais-search-box {
    display: flex;
    flex: 1;
  }
}

.c-search-bar__input,
.c-search-bar__filtering,
.c-SearchBar-button {
  height: auto;
}

.c-search-bar__input {
  background: #fff;
  border: none;
  border-radius: 0;
  color: $global-text-color;
  flex: 1;
  font-size: 1em;
  height: auto;
  padding-left: 1em;
  padding-right: 1em;
}

.c-search-bar__filtering {
  align-items: stretch;
  background: #fff;
  color: $global-text-color;
  display: flex;
  font-size: .833em;
  position: relative;

  > input {
    display: none;

    &:checked {
      + label {
        background: $filters-background;
        color: $filters-color;
      }

      ~ .c-search-bar__filters {
        display: block;
      }
    }
  }

  > label {
    align-items: center;
    border-right: 1px solid $grey-lighter;
    display: flex;
    justify-content: center;
    padding: 0 22px 0 9px;
    position: relative;
    width: 100%;

    &:after,
    &:before {
      border-bottom: 2px solid;
      border-left: 2px solid;
      content: '';
      display: block;
      height: 7px;
      margin: -3px 0 0 0;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: rotate(-45deg);
      width: 7px;
    }

    &:before {
      animation: colorMotion 1s ease-in-out infinite;
      animation-delay: 0.8s;
    }

    &::after {
      animation: colorMotion2 1s ease-in-out infinite;
      color: $grey;
      margin-top: -8px;
    }
  }
}

.c-search-bar__filters {
  background: $filters-background;
  color: $filters-color;
  display: none;
  font-size: .833rem;
  left: 0;
  overflow: hidden;
  margin: 0;
  min-width: 100%;
  position: absolute;
  top: 100%;
  width: 150px;
  z-index: 1;

  input {
    display: none;

    &:checked {
      + label {
        background: #000;
        color: #fff;
      }
    }
  }

  label {
    align-items: center;
    display: flex;
    padding: 13px;
    transition: background-color 250ms ease-in;

    &:hover,
    &:focus,
    &:active {
      background-color: #000;
    }
  }
}

.c-SearchBar-button {
  border-radius: 0;
  padding: 0 9px;
  width: 54px;

  .c-Icon {
    vertical-align: middle;
    width: 24px;
  }
}

.c-search-bar--lg {
  height: $search-bar-height-lg;
}

@include mq($from:md) {
  .c-SearchBar {
    font-size: 1.2em;
  }
}
