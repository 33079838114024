.c-account-nav {
  background: #000;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  /* For WebKit implementations, provide inertia scrolling */
  -webkit-overflow-scrolling: touch;

  .js & {
    /* Make an auto-hiding scroller IE */
    -ms-overflow-style: -ms-autohiding-scrollbar;

    /* Remove the default scrollbar for WebKit implementations */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.c-account-nav__list {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

.c-account-nav__action {
  border-top: 4px solid transparent;
  color: #fff;
  display: block;
  font-size: .75em;
  font-weight: 400;
  position: relative;
  padding: 1.5em 1em;
  text-decoration: none;
  z-index: 1;

  &[aria-selected=true] {
    background: $grey-800;
    border-top-color: $teal-500;
  }
}

@include mq($from: sm) {
  .c-account-nav {
    background: transparent;
    position: relative;
  }

  .c-account-nav__list {
    border-top: none;
    justify-content: center;
  }

  .c-account-nav__action {
    color: #000;
    font-size: 1em;

    &:hover,
    &:focus {
      background: $grey-100;
    }

    &[aria-selected=true] {
      background: $grey-300;
      border-top: 4px solid $teal-500;

      &::before {
        bottom: 1.5em;
        left: .75em;
        right: .75em;
        top: auto;
        width: auto;
      }
    }
  }
}

@include mq($from: md) {
  .c-account-nav__action {
    font-size: 1.2em;
  }
}
