/* ==========================================================================
   #GRID
   ========================================================================== */

/**
* Core grid component
*
* DO NOT apply dimension or offset utilities to the `Grid` element. All cell
* widths and offsets should be applied to child grid cells.
*/

/* Grid container
  ========================================================================== */

/**
* All content must be contained within child `Grid-cell` elements.
*
* 1. Account for browser defaults of elements that might be the root node of
*    the component.
*/

.o-grid {
  box-sizing: border-box;
  display: flex; /* 1 */
  flex-flow: row wrap;
  margin: 0; /* 1 */
  padding: 0; /* 1 */
}

.o-grid--reverse {
  flex-direction: row-reverse;
}

/**
* Modifier: center align all grid cells
*/

.o-grid--align-center {
  justify-content: center;
}

/**
* Modifier: right align all grid cells
*/

.o-grid--align-right {
  justify-content: flex-end;
}

/**
* Modifier: middle-align grid cells
*/

.o-grid--align-middle {
  align-items: center;
}

/**
* Modifier: bottom-align grid cells
*/

.Grid--align-bottom {
  align-items: flex-end;
}

/**
* Modifier: allow cells to equal distribute width
*
* 1. Provide all values to avoid IE10 bug with shorthand flex
*    http://git.io/vllC7
*
*    Use `0%` to avoid bug in IE10/11 with unitless flex basis
*    http://git.io/vllWx
*/

.o-grid--fit > * {
  flex: 1 1 0%; /* 1 */
}

/**
* Modifier: all cells match height of tallest cell in a row
*/

.o-grid--equal-height > * {
  display: flex;
}

/**
* Modifier: gutters
*/

.o-grid--with-gutter {
  margin: 0 -1rem;

  > * {
    padding: 0 1rem;
  }
}

.o-grid--gutter-compact {
  margin: 0 -.482rem;

  > * {
    padding: 0 .482rem;
  }
}

/* Grid cell
  ========================================================================== */

/**
* No explicit width by default. Rely on combining `Grid-cell` with a dimension
* utility or a component class that extends 'Grid'.
*
* 1. Set flex items to full width by default
* 2. Fix issue where elements with overflow extend past the
*    `Grid-cell` container - https://git.io/vw5oF
*/

.o-grid > * {
  box-sizing: inherit;
  min-width: 0; /* 2 */
  width: 100%; /* 1 */
}
