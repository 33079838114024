/**
 * <div class="c-ImageSign">
 *   <img class="c-ImageSign-image" src="" alt="">
 *
 *   <div class="c-ImageSign-sign">
 *     <a class="c-ImageSign-title" href="">This is the title</a>
 *   </div><!--/image sign -->
 * </div><!--/image sign component -->
 */

.c-ImageSign {
  display: block;
}

.c-ImageSign-sign {
  background: #fff;
  box-shadow: $global-high-shadow;
  display: block;
  font-size: 1rem;
  margin: -1.728em .833em 0;
  padding: .833em .488em;
  position: relative;
  text-align: center;
}

.c-ImageSign-title {
  color: inherit;
  display: block;
  font-weight: $global-bold-weight;
  text-decoration: none;
  text-transform: uppercase;
}
