.c-Products-text {
  background: #000;
  color: #fff;
  padding: $global-spacing-unit $global-spacing-unit 0;
  position: relative;
  z-index: 1;
}

.c-Products-figure {
  min-width: 0;
}

.c-Products-list {
  margin: 0 -#{$global-spacing-unit};
  overflow-y: hidden;
}

.c-Products-scroll {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 -30px;
  overflow-x: scroll;
  padding: 0 0 30px $global-spacing-unit;
}

.c-Products-product {
  display: block;
  padding: .335em;
  position: relative;

  a {
    color: currentColor;
    display: block;
    padding: .335em;
    position: relative;
    text-decoration: none;
  }

  &.is-active {
    &::before {
      background: $teal;
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

@include mq($from: md) {
  .c-Products {
    display: flex;
  }

  .c-Products-text {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 70% 100%, 0 100%);
    max-width: 400px;
    width: 33%;
  }

  .c-Products-figure {
    flex: 1;
    margin-left: -10%;

    .slick-list,
    .slick-track,
    .slick-slide img {
      height: 100%;
    }

    .slick-slide img {
      object-fit: cover;
      width: 100%;
    }
  }

  .c-Products-scroll {
    display: block;
  }
}
