.c-pricing-table__row {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.c-pricing-table__title,
.c-pricing-table__price-col {
  padding-bottom: 2.074em;
  padding-top: 1em;
}

.c-pricing-table__title {
  margin: 0 auto;
  max-width: 30rem;
  padding-top: 0;
}

.c-pricing-table__figure,
.c-pricing-table__text {
  text-align: center;
}

.c-pricing-table__figure {
  color: $grey;
}

.c-pricing-table__prices {
  display: flex;
  flex: 3;
  justify-content: flex-start;
}

.c-pricing-table__price-col {
  border-right: 1px solid $grey-light;
  padding: 1rem 1rem 2rem;
  position: relative;
  text-align: center;
  width: calc(1 / 3 * 100%);

  &:last-child:not(:only-child) {
    border-right: none;
  }
}

.c-pricing-table__price-name {
  color: $grey;
  font-size: .875em;
}

.c-pricing-table__price-amount {
  font-size: 1.875em;
}

.c-pricing-table__price-type {
  display: block;
  font-size: .875em;
}

@include mq($from: md) {
  .c-pricing-table__row {
    flex-direction: row;
    margin: 0;

    &:first-child {
      padding-top: 2em;
    }

    .c-pricing-table__row:last-of-type {
      & .c-pricing-table__title,
      & .c-pricing-table__price-col {
        padding-bottom: 0;
      }
    }
  }

  .c-pricing-table__title {
    display: flex;
    flex: 7;
    margin-left: 0;
  }

  .c-pricing-table__text {
    flex: 1;
    padding: 0 1rem;
    text-align: left;
  }

  .c-pricing-table__price-name {
    display: none;
    left: 0;
    position: absolute;
    top: -2rem;
    width: 100%;

    .c-pricing-table__row:first-child & {
      display: block;
    }
  }
}
