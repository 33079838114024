/* ==========================================================================
   #TABLES
   ========================================================================== */

/**
 * 1. Ensure tables fill up as much space as possible.
 */

table {
  width: 100%; /* [1] */

  th { text-align:inherit; }

  td,
  th {
    padding: .833em;
    vertical-align: top;
  }

  thead th { vertical-align: bottom; }
}
