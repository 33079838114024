.c-drawer {
  background: #fff;
  bottom: 0;
  color: #333;
  display: flex;
  flex-flow: column;
  max-width: 95%;
  position: fixed;
  top: 0;
  transition: all .3s ease-in;
  z-index: 1000;

  .js-drawer-open & {
    box-shadow:0 2px 20px 4px rgba(0,0,0,.05);
  }
}

.c-drawer--right {
  margin-right:-280px;
  -webkit-box-ordinal-group:2;
  -ms-flex-order:1;
  order:1;
  right:0;
  width:280px;

  .js-drawer-open-right & {
    margin-right:0;
  }
}

.c-drawer--left {
  order:-1;
  margin-left:-280px;
  width:280px;

  .js-drawer-open-left & {
    margin-left:0
  }
}

.c-drawer__close {
  color: #fff;
  font-size: 1.728em;
  left: 0;
  line-height: 44px;
  margin: 1rem 1rem 0 0;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 44px;
  z-index: 1;

  .c-drawer--right & {
    left:auto;
    right:0;
  }
}

.c-drawer__title {
  background: #000;
  color: #fff;
  font-size:1em;
  font-weight:700;
  line-height: 50px;
  margin: 1em;
  padding:0 44px;
  text-align:center;
  text-transform:uppercase;
}

.c-drawer__body {
  display: flex;
  flex: 1;
}

.c-drawer__footer {
  border-top: 1px solid $grey-light;
}

@media (min-width:50em) {
  .c-drawer--page { position:relative; }

  .c-drawer--right {
    margin-right:-320px;
    width:320px;

    .js-drawer-open-right & {
      margin-right:0
    }
  }

  .c-drawer--left {
    margin-left:-320px;
    width:320px;

    .js-drawer-open-left & {
      margin-left:0
    }
  }
}

@include mq($from: lg) {
  .c-drawer--right {
    margin-right:-400px;
    width:400px;

    .js-drawer-open-right & {
      margin-right:0;
    }
  }

  .c-drawer--left {
    margin-left:-400px;
    width:400px;

    .js-drawer-open-left & {
      margin-left:0;
    }
  }
}
