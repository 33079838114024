.c-dropdown-menu {
  background-color: #fff;
  border: 1px solid $grey-lighter;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
  font-size: 1rem;
  left: 0;
  min-width: 10rem;
  opacity: 0;
  position: absolute;
  text-align: left;
  top: 100%;
  transform: scale(.8);
  transform-origin: top left;
  transition: all 100ms ease-in;
  visibility: hidden;
  z-index: 1000;

  a {
    color: inherit;
    text-decoration: none;
  }

  &.is-showing {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }
}

.c-dropdown-menu--right {
  left: auto;
  right: 0;
  transform-origin: top right;
}

.c-dropdown-menu__item {
  display: block;
  padding: .482rem 1rem;
  white-space: nowrap;
  width: 100%;

  &:hover {
    background-color: $grey-lighter;
  }
}

.c-dropdown-menu__divider {
  border-top: 1px solid $grey-light;
  height: 0;
  margin: .335rem 0;
}



/* =============================================================================
   Mini Cart
   ========================================================================== */

.c-mini-cart__header,
.c-mini-cart__items,
.c-mini-cart__footer {
  padding: 1rem;
}

.c-mini-cart__header {
  background: $grey-200;
  display: flex;
}

.c-mini-cart__heading {
  font-size: 1em;
  padding-right: 1em;
}

.c-mini-cart__header-action {
  color: $grey-dark;
  font-size: .833em;
  text-decoration: none;
}

.c-mini-cart__items {
  max-height: 300px;
  overflow: auto;
}

.c-mini-cart__footer {
  border-top: 1px solid $grey-lighter;
  box-shadow: 0 -2px 4px rgba(0,0,0,.1);
}
