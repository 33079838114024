.c-Detail {
  margin: 0 auto;
  max-width: 1112px;
}

.c-Detail-body {
  grid-area: detailBody;
  padding: $global-spacing-unit;
}

.c-Detail-aside {
  grid-area: detailAside;
  min-width: 0; /* slick slider hack to keep it within this parent */
  padding: $global-spacing-unit 0;
}

.c-Detail-kicker {
  background: $grey-lighter;
  display: inline-block;
  padding: 4px;
  text-transform: uppercase;
}

.c-Detail-title {
  font-weight: $global-normal-weight;
}

.c-Detail-meta {
  font-size: .833em;
  margin-bottom: $global-spacing-unit;
  padding-top: $global-spacing-unit;
}

@include mq($from: 500px) {
  .c-Detail-figure {
    img {
      max-width: 540px;
    }
  }
}

@include mq($from: 1000px) {
  .c-Detail {
    display: grid;
    grid-template-areas:
    "detailFigure detailBody"
    "detailAside detailAside";
    grid-template-columns: 600px 1fr;
    padding-top: $global-spacing-unit;
  }
}
