.c-icon {
  display: inline-block;
  fill: currentColor;
  vertical-align: text-top;
}

.c-icon--sm {
  height: 24px;
  width: 24px;
}

.c-icon--md {
  height: 36px;
  width: 36px;
}

.c-icon--lg {
  height: 48px;
  width: 48px;
}

.c-icon-outline {
  border: 3px solid;
  border-radius: 100%;
  display: inline-block;
  line-height: 1;
  padding: 8px;
}
