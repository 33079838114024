.o-prose {
  > ul {
    list-style-type: square;
  }

  > ol {
    list-style-type: decimal;
  }

  > ul,
  > ol {
    > li {
      margin-left: 1em;
      margin-bottom: .5rem;
    }
  }

  h1,h2,h3,h4,h5,h6,
  p,
  ul,
  ol {
    margin-bottom: $global-spacing-unit;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
