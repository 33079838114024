/* Padding
   ========================================================================== */

/* Padding all */
.u-p-0 { padding: 0; }
.u-p-1 { padding: .25rem; }
.u-p-2 { padding: .5rem; }
.u-p-3 { padding: .75rem; }
.u-p-4 { padding: 1rem; }
.u-p-5 { padding: 1.25rem; }
.u-p-6 { padding: 1.5rem; }
.u-p-8 { padding: 2rem; }
.u-p-10 { padding: 2.5rem; }
.u-p-12 { padding: 3rem; }
.u-p-16 { padding: 4rem; }
.u-p-20 { padding: 5rem; }
.u-p-24 { padding: 6rem; }
.u-p-32 { padding: 8rem; }
.u-p-px { padding:1px; }

/* padding horizontal */
.u-px-1 { padding-left: .25rem; padding-right: .25rem; }
.u-px-2 { padding-left: .5rem; padding-right: .5rem; }
.u-px-3 { padding-left: .75rem; padding-right: .75rem; }
.u-px-4 { padding-left: 1rem; padding-right: 1rem; }
.u-px-8 { padding-left: 2rem; padding-right: 2rem; }

/* padding vertical */
.u-py-3 { padding-bottom: .75rem; padding-top: .75rem; }
.u-py-4 { padding-bottom: 1rem; padding-top: 1rem; }
.u-py-5 { padding-bottom: 1.24rem; padding-top: 1.25rem; }
.u-py-6 { padding-bottom: 1.5rem; padding-top: 1.5rem; }
.u-py-10 { padding-bottom: 2.5rem; padding-top: 2.5rem; }
.u-py-12 { padding-bottom: 3rem; padding-top: 3rem; }
.u-py-16 { padding-bottom: 4rem; padding-top: 4rem; }
.u-py-20 { padding-bottom: 5rem; padding-top: 5rem; }
.u-py-24 { padding-bottom: 6rem; padding-top: 6rem; }

@include mq($from: md) {
  .md\:u-py-0 { padding-bottom: 0; padding-top: 0; }
}

@include mq($from: lg) {
  .lg\:u-py-0 { padding-bottom: 0; padding-top: 0; }
  .lg\:u-pt-10,
  .lg\:u-py-10 { padding-top:4.3rem; }

  .lg\:u-pb-10,
  .lg\:u-py-10 { padding-bottom:4.3rem; }
}

/* Padding top */
.u-pt-0 { padding-top: 0; }

.u-pt-1,
.u-py-1 { padding-top: .25rem; }

.u-pt-2,
.u-py-2 { padding-top:.5rem; }

.u-pt-4 { padding-top: 1rem; }
.u-pt-5 { padding-top: 1.25rem; }
.u-pt-6 { padding-top: 1.5rem; }
.u-pt-8,
.u-py-8 { padding-top: 2rem; }
.u-pt-10 { padding-top: 2.5rem; }
.u-pt-12 { padding-top: 3rem; }
.u-pt-16 { padding-top: 4rem; }
.u-pt-20 { padding-top: 5rem; }
.u-pt-24 { padding-top: 6rem; }
.u-pt-32 { padding-top: 8rem; }
.u-pt-64 { padding-top: 16rem; }



/* Padding right  */
.u-pr-2,
.u-px-2 { padding-right: .5rem; }
.u-pr-4 { padding-right: 1rem; }

.u-pr-5,
.u-px-5 { padding-right: 1.25rem; }
.u-pr-12 { padding-right: 3rem; }
.u-pr-32 { padding-right: 8rem; }

/* Padding bottom */
.u-pb-0 { padding-bottom: 0; }
.u-pb-1,
.u-py-1 { padding-bottom: .25rem; }

.u-pb-2,
.u-py-2 { padding-bottom: .5rem; }
.u-pb-4 { padding-bottom: 1rem;}
.u-pb-5 { padding-bottom: 1.25rem; }
.u-pb-6 { padding-bottom: 1.5rem; }

.u-pb-7,
.u-py-7 { padding-bottom:1.5rem; }

.u-pb-8,
.u-py-8 { padding-bottom: 2rem; }
.u-pb-9,
.u-py-9 { padding-bottom:2.986rem; }
.u-pb-10 { padding-bottom: 2.5rem; }
.u-pb-16 { padding-bottom: 4rem; }
.u-pb-20 { padding-bottom: 5rem; }
.u-pb-32 { padding-bottom: 8rem; }

@include mq($from: sm) {
  .sm\:u-pb-24 { padding-bottom: 6rem; }
}

@include mq($from: md) {
  .md\:u-pb-0 { padding-bottom: 0; }
}

/* Padding left */
.u-pl-2,
.u-px-2 { padding-left: .5rem; }
.u-pl-4 { padding-left: 1rem; }
.u-pl-5,
.u-px-5 { padding-left: 1.25rem; }
.u-pl-8 { padding-left: 2rem; }

@include mq($from: md) {
  .md\:u-p-8 { padding: 2rem; }
  .md\:u-p-24 { padding: 6rem; }

  .md\:u-py-24 { padding-bottom: 6rem; padding-top: 6rem; }
}

@include mq($from: lg) {
  .lg\:u-p-8 { padding: 2rem; }
  .lg\:u-p-24 { padding: 6rem; }

  .lg\:u-py-24 { padding-bottom: 6rem; padding-top: 6rem; }
}



/* Margin
   ========================================================================== */

/* All */
.u-m-0 { margin:0; }
.u-m-4 { margin: 1rem; }

/* margin horizontal */
.u-mx-2 { margin-left: .5rem; margin-right: .5rem; }
.u-mx-3 { margin-left: .75rem; margin-right: .75rem; }
.u-mx-4 { margin-left: 1rem; margin-right: 1rem; }
.u-mx-5 { margin-left: 1.25rem; margin-right: 1.25rem; }
.u-mx-auto { margin-left: auto; margin-right: auto; }

@include mq($from: sm) {
  .sm\:u-mx-auto { margin-left: auto; margin-right: auto; }
}

/* margin vertical */
.u-my-4 { margin-bottom: 1rem; margin-top: 2rem; }
.u-my-8 { margin-bottom: 2rem; margin-top: 2rem; }

/* Margin top */
.u-mt-2 { margin-top:.5rem; }
.u-mt-4 { margin-top: 1rem; }

.u-mt-5,
.u-my-5 { margin-top: 1.25rem; }

.u-mt-6,
.u-my-6 { margin-top:1.5rem; }
.u-mt-8 { margin-top: 2rem; }

.u-mt-10,
.u-my-10 { margin-top: 2.5rem; }
.u-mt-12 { margin-top: 3rem; }
.u-mt-16 { margin-top: 4rem; }
.u-mt-20 { margin-top: 5rem; }
.u-mt-32 { margin-top: 8rem; }

.u-mt-auto { margin-top:auto; }

/* Margin right */
.u-mr-1 { margin-right: .25rem; }
.u-mr-2 { margin-right:.5rem; }
.u-mr-3 { margin-right:.75rem; }
.u-mr-4 { margin-right: 1rem; }
.u-mr-5 { margin-right: 1.25rem; }
.u-mr-8 { margin-right: 2rem; }
.u-mr-auto { margin-right:auto; }

@include mq($from: sm) {
  .sm\:u-mr-0 { margin-right: 0; }
}

@include mq($from: lg) {
  .lg\:u-mr-0 { margin-right: 0; }
}

/* Margin bottom */
.u-mb-0 { margin-bottom: 0; }
.u-mb-1 { margin-bottom: .25rem; }
.u-mb-2 { margin-bottom: .5rem; }

.u-mb-4 { margin-bottom: 1rem; }
.u-mb-5,
.u-my-5 { margin-bottom: 1.25rem; }

.u-mb-6,
.u-my-6 { margin-bottom: 1.5rem; }
.u-mb-8 { margin-bottom: 2rem; }
.u-mb-10,
.u-my-10 { margin-bottom: 2.5rem; }
.u-mb-12 { margin-bottom: 3rem; }
.u-mb-16 { margin-bottom: 4rem; }
.u-mb-20 { margin-bottom: 5rem; }
.u-mb-24 { margin-bottom: 6rem; }
.u-mb-32 { margin-bottom: 8rem; }
.u-mb-px { margin-bottom: 1px; }

/* margin left */
.u-ml-1 { margin-left: .25rem; }
.u-ml-2 { margin-left: .5rem; }
.u-ml-3 { margin-left: .75rem; }
.u-ml-4 { margin-left: 1rem; }
.u-ml-5 { margin-left: 1.25rem; }
.u-ml-6 { margin-left: 1.5rem; }
.u-ml-8 { margin-left: 2rem; }
.u-ml-10 { margin-left: 2.5rem; }
.u-ml-auto { margin-left: auto; }

@include mq($from: sm) {
  .sm\:u-ml-0 { margin-left: 0; }

  /* margin right */
  .sm\:u-mr-1 { margin-right: .25rem; }
  .sm\:u-mr-2 { margin-right: .5rem; }
  .sm\:u-mr-4 { margin-right: 1rem; }

  /* margin bottom */
  .sm\:u-mb-0 { margin-bottom: 0; }
  .sm\:u-mb-32 { margin-bottom: 8rem; }

  /* margin left */
  .sm\:u-ml-auto { margin-left: auto; }
}

@include mq($from: md) {
  .md\:u-mr-4,
  .md\:u-mx-4 { margin-right: 1rem; }
  .md\:u-mr-8 { margin-right: 2rem; }

  /* margin bottom */
  .md\:u-mb-0 { margin-bottom: 0; }

  .md\:u-ml-0,
  .md\:u-mx-0 { margin-left: 0; }
}

@include mq($from: lg) {
  .lg\:u-mb-0 { margin-bottom: 0; }
}


/* Negative margin
   ========================================================================== */

/* negative horizontal */
.u--mx-2 { margin-left: -0.5rem; margin-right: -0.5rem; }
.u--mx-4 { margin-left: -1rem; margin-right: -1rem; }

/* negative top */
.u--mt-4 { margin-top: -1rem; }
.u--mt-8 { margin-top: -2rem; }
.u--mt-10 { margin-top: -2.5rem; }
.u--mt-16 { margin-top: -4rem; }
.u--mt-32 { margin-top: -8rem; }

/* negative right */
.u--mr-4 { margin-right: -1rem; }
.u--mr-10 { margin-right: -2.5rem; }
.u--mr-12 { margin-right: -3rem; }
.u--mr-24 { margin-right: -6rem; }

/* negative bottom */
.u--mb-4 { margin-bottom: -1rem; }
.u--mb-16 { margin-bottom: -4rem; }

/* negative left */
.u--ml-4 { margin-left: -1rem; }
.u--ml-8 { margin-left: -2rem; }

@include mq($from: sm) {
  /* padding */
  .sm\:u-p-12 { padding: 3rem; }
  .sm\:u-pt-0 { padding-top: 0; }
  .sm\:u-pt-8 { padding-top: 2rem; }
  .sm\:u-pb-20 { padding-bottom: 5rem; }
  .sm\:u-pb-32 { padding-bottom: 8rem; }
  .sm\:u-pl-4 { padding-left: 1rem; }

  /* margin */
  .sm\:u-mt-0 { margin-top: 0; }
  .sm\:u-mt-8 { margin-top: 2rem; }
  .sm\:u-mr-auto { margin-right: auto; }
  .sm\:u-mb-16 { margin-bottom: 4rem; }
  .sm\:u-mb-20 { margin-bottom: 5rem; }
  .sm\:u-ml-4 { margin-left: 1rem; }
  .sm\:u-ml-auto { margin-left: auto; }
}

@include mq($from: sm) {
  /* padding */
  .sm\:u-p-8 { padding: 2rem; }
  .sm\:u-p-10 { padding: 2.5rem; }
}

@include mq($from: md) {
  /* padding */
  .md\:u-p-4 { padding: 1rem; }

  .md\:u-px-8 { padding-left: 2rem; padding-right: 2rem; }

  .md\:u-py-10 { padding-bottom: 2.5rem; padding-top: 2.5rem; }
  .md\:u-py-12 { padding-bottom: 3rem; padding-top: 3rem; }
  .md\:u-py-16 { padding-bottom: 4rem; padding-top: 4rem; }

  .md\:u-pt-10 { padding-top: 2.5rem; }
  .md\:u-pt-16 { padding-top: 4rem; }

  .md\:u-pb-10 { padding-bottom: 2.5rem; }
  .md\:u-pb-24 { padding-bottom: 6rem; }

  /* margins */
  .md\:u-m-8 { margin: 2rem; }
  .md\:u-mx-8 { margin-left: 2rem; margin-right: 2rem; }
  .md\:u-mt-8 { margin-top: 2rem; }
  .md\:u-mr-0 { margin-right: 0; }
  .md\:u-mb-0 { margin-bottom: 0; }
  .md\:u-mb-8 { margin-bottom: 2rem; }
  .md\:u-mb-16 { margin-bottom: 4rem; }
  .md\:u-ml-8 { margin-left: 2rem; }

  /* negative margins */

}

@include mq($from: lg) {
  /* padding */
  .lg\:u-p-16 { padding: 4rem; }
  .lg\:u-py-16 { padding-bottom: 4rem; padding-top: 4rem; }
  .lg\:u-py-32 { padding-bottom: 8rem; padding-top: 8rem;}
  .lg\:u-pt-16 { padding-top: 4rem; }
  .lg\:u-pt-32 { padding-top: 8rem; }

  /* margins */
  .lg\:u-mr-0 { margin-right: 0; }
  .lg\:u-mb-32 { margin-bottom: 8rem; }
  .lg\:u-ml-8 { margin-left: 2rem; }

  /* negative margins */
  .lg\:u--mt-8 { margin-top: -2rem; }
  .lg\:u--mt-32 { margin-top: -8rem; }
  .lg\:u--ml-12 { margin-left: -3rem; }
}

@include mq($from: xl) {
  /* padding */
  .xl\:u-pt-16 { padding-top: 4rem; }

  /* margins */
  .xl\:u-mx-16 { margin-left: 4rem; margin-right: 4rem; }
  .xl\:u-mt-16 { margin-top: 4rem; }
}
