.c-Results {
  position: relative;
}

.ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
  grid-gap: 10px;
  grid-auto-flow: dense;
  list-style: none;
  padding: 0;

  img {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  @include mq($from: md) {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

.c-Results-content {
  flex: 1;
  padding: 10px 0;

  .ais-infinite-hits {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
    grid-gap: 10px;
    grid-auto-flow: dense;
    list-style: none;
    padding: 0;

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .ais-infinite-hits__empty {
    display: block;
  }

  .ais-infinite-hits--showmore {
    margin: 1em 0;

    button {
      border: 2px solid #000;
      box-shadow: $global-high-shadow;
      color: #000;
      font-size: 1em;
      font-weight: $global-bold-weight;
      padding: 1em;
      transition: 300ms ease all;
      width: 100%;

      &[disabled] {
        display: none;
      }

      &:hover,
      &:focus,
      &:active {
        background: #fff;
      }

      &:focus {
        border-color: $teal;
        outline: none;
      }

      &:active {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.c-Results-values {
  background: $grey-lighter;
  margin-bottom: 9px;
  padding: 9px;
}

@include mq($from: 490px) {
  .c-Results-content {
    .ais-infinite-hits {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
  }
}

@include mq($from: 800px) {
  .c-Results {
    display: flex;
  }

  .c-Results-filters {
    padding: 10px;
    width: 300px;


    .c-Filters-body {
      display: block;
    }

    .c-Hamburger {
      display: none;
    }
  }
}
