[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
  appearance: none;
  background: transparent;
  border: 1px solid $grey-lighter;
  border-radius: 4px;
  color: $grey-darker;
  display: block;
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
  height: $global-input-height;
  padding: 0 .694em;
  transition: border-color .3s ease-in-out;
  width: 100%;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  line-height: $global-line-height / $global-font-size;
  
  &:not([disabled]) {
    cursor: pointer;
  }
}

button[disabled] {
  opacity: .25;
}

textarea {
  max-width: 100%;
}

/*textarea[rows] {
  background: $grey-lightest;
  height: auto;
  padding: .335em;
}*/

select {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
}

input::placeholder,
textarea::placeholder {
  color: $grey;
}

input:required::placeholder,
textarea:required::placeholder {
  color: $grey-darker;
}

label {
  cursor: pointer;
  font-size: .833em;
}
