.u-text-truncate{
  max-width:100%;
  overflow:hidden!important;
  text-overflow:ellipsis!important;
  white-space:nowrap!important;
  word-wrap:normal!important;
}

/* Fonts
   ========================================================================== */
.u-font-display{ font-family: $display-font-family; }


/* Colors
   =========================================================================  */
.u-text-black{color:#000}
.hover\:u-text-black { color: #000; }

.u-text-grey-900 { color: $grey-900; }
.u-text-grey-darker,
.u-text-grey-800 { color: $grey-800 }
.u-text-grey-dark,
.u-text-grey-700 { color: $grey-700; }
.u-text-grey-600 { color: $grey-600; }
.u-text-grey,
.u-text-grey-500 { color: $grey-500; }
.u-text-grey-400 { color: $grey-400; }
.u-text-grey-light,
.u-text-grey-300 { color: $grey-300; }
.u-text-grey-lighter,
.u-text-grey-200 { color: $grey-200; }
.u-text-grey-100 { color: $grey-100; }

.hover\:u-text-grey-900:hover { color: $grey-900; }

.u-text-navy-light{ color:#e6f9ff; }

.hover\:u-text-orange,
.u-text-orange { color: $orange; }

.u-text-purple { color: $purple; }

.u-text-teal-300,
.u-text-teal-light { color: $teal-300; }
.u-text-teal-500,
.u-text-teal { color: $teal-500; }
.u-text-teal-600 { color: $teal-600; }
.u-text-teal-700 { color: $teal-700; }
.u-text-teal-800 { color: $teal-800; }
.u-text-teal-900 { color: $teal-900; }

.u-text-blue-500 { color: $blue-500; }

.hover\:u-text-white:hover,
.u-text-white{color:#fff}

.u-text-current{color:currentColor}

/* Sizing
   ========================================================================== */
.u-text-xs { font-size: .75rem; }
.u-text-sm { font-size: .875rem; }
.u-text-base { font-size: 1rem; }
.u-text-lg { font-size: 1.125rem; }
.u-text-xl { font-size: 1.25rem; }
.u-text-2xl { font-size: 1.5rem; }
.u-text-3xl { font-size: 1.875rem; }
.u-text-4xl { font-size: 2.25rem; }
.u-text-5xl { font-size: 3rem; }
.u-text-6xl { font-size: 4rem; }
.u-text-7xl { font-size: 5rem; }

@include mq($from: sm) {
  .sm\:u-text-xl{font-size:1.25rem; }
  .sm\:u-text-2xl{font-size:1.5rem; }
  .sm\:u-text-3xl{font-size:1.875rem; }
  .sm\:u-text-5xl { font-size: 3rem; }
}

@include mq($from: md) {
  .md\:u-text-lg { font-size: 1.125rem; }
  .md\:u-text-xl { font-size: 1.25rem; }
  .md\:u-text-2xl { font-size: 1.5rem; }
  .md\:u-text-3xl { font-size: 1.875rem; }
  .md\:u-text-4xl { font-size: 2.25rem; }
  .md\:u-text-5xl{ font-size: 3rem; }
  .md\:u-text-6xl { font-size: 4rem; }
}

@include mq($from: lg) {
  .lg\:u-text-3xl { font-size: 1.875rem; }
  .lg\:u-text-4xl { font-size: 2.25rem; }
  .lg\:u-text-5xl { font-size: 3rem; }
  .lg\:u-text-6xl { font-size: 4rem; }
}

@include mq($from: xl) {
  .xl\:u-text-6xl { font-size: 4rem; }
  .xl\:u-text-7xl { font-size: 5rem; }
}

/* Weights
   ========================================================================== */

.u-font-normal{ font-weight:400; }
.u-font-bold{ font-weight:700; }
.u-font-extrabold { font-weight: 800; }
.u-font-black{ font-weight:900; }

/* Alignment
   ========================================================================== */
.u-text-left { text-align: left; }
.u-text-center{text-align:center}
.u-text-right{text-align:right}

/* Line height
   ========================================================================== */
.u-leading-none { line-height: 1; }
.u-leading-tight { line-height: 1.25; }
.u-leading-normal { line-height: 1.5 }
.u-leading-loose { line-height: 2; }

/* Letter spacing
   ========================================================================== */
.u-tracking-tighter { letter-spacing: -0.05em; }
.u-tracking-tight { letter-spacing: -0.025em; }
.u-tracking-wide { letter-spacing: 0.025em; }
.u-tracking-wider { letter-spacing: 0.05em; }

/* Style & Decoration
   ========================================================================== */
.u-uppercase{text-transform:uppercase}
.u-underline { text-decoration: underline; }
.u-no-underline{text-decoration:none}

/* Vertical Alignment
   ========================================================================== */
.u-align-text-top { vertical-align: text-top; }

/* Whitespace & Wrapping
   ========================================================================== */
.u-whitespace-no-wrap { white-space: nowrap; }

@include mq($from: md) {
  /* alignment */
  .md\:u-text-left { text-align: left; }
  .md\:u-text-center { text-align: center; }
  .md\:u-text-right { text-align: right; }
}
