/* =============================================================================
   #BOX
   ========================================================================== */

.o-box {
  display: block;
  padding: $global-spacing-unit;

  > :last-child {
    margin-bottom: 0;
  }
}

.o-box--vertical {
  padding-left: 0;
  padding-right: 0;
}

.o-box--horizontal {
  padding-bottom: 0;
  padding-top: 0;
}

.o-box--tall {
  padding-bottom: $global-spacing-unit-xl;
  padding-top: $global-spacing-unit-xl;
}
