/* Width
   ========================================================================== */
/* All */
.u-border-0 { border-width: 0; }
.u-border { border-width: 1px; }
.u-border-2 { border-width: 2px; }
.u-border-4 { border-width: 4px; }
.u-border-8 { border-width: 8px; }

/* Top */
.u-border-t { border-top-width: 1px; }
.u-border-t-4 { border-top-width: 4px; }

/* Right */
.u-border-r { border-right-width: 1px; }

/* Bottom */
.u-border-b-0 { border-bottom: 0px; }
.u-border-b { border-bottom-width: 1px; }

/* Left */
.u-border-l { border-left-width: 1px; }
.u-border-l-2 { border-left-width: 2px; }
.u-border-l-4 { border-left-width: 4px; }

/* Color
   ========================================================================== */
.u-border-black { border-color: #000; }
.u-border-white { border-color: #fff; }

.u-border-grey-100 { border-color: $grey-100; }
.u-border-grey-lighter,
.u-border-grey-200 { border-color: $grey-200; }
.u-border-grey-light,
.u-border-grey-300 { border-color: $grey-300; }
.u-border-grey-400 { border-color: $grey-400; }
.u-border-grey,
.u-border-grey-500 { border-color: $grey-500; }
.u-border-grey-600 { border-color: $grey-600; }
.u-border-grey-darker,
.u-border-grey-700 { border-color: $grey-700; }
.u-border-grey-800 { border-color: $grey-800; }
.u-border-grey-900 { border-color: $grey-900; }

.u-border-teal-300 { border-color: $teal-300; }
.u-border-teal-500,
.u-border-teal { border-color: $teal-500; }
.u-border-teal-900 { border-color: $teal-900; }

.u-border-current { border-color: currentColor; }

.u-border-l-transparent { border-left-color: transparent; }


/* Style
   ========================================================================== */
.u-border-solid { border-style: solid; }
.u-border-dotted { border-style: dotted; }
