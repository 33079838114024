/* ==========================================================================
   #GLOBAL
   ========================================================================== */

/**
 * The global settings file contains any project-wide variables; things that
 * need to be made available to the entire codebase.
 */


/* Global widths
   ========================================================================== */
/**
 * Convert site width into ems
 * http://bradfrost.com/blog/post/7-habits-of-highly-effective-media-queries/#relative
**/
$global-site-width: 1366px / 16px * 1em;


/**
 * Content width for better line length readability
 *
 * 1. Using rem so not to use individual element sizing, but rather root sizing
 */
$global-content-width: 45rem; /* 1 */


/* Grey color palette
   ========================================================================== */
$grey-500:                      #a0aec0;
$grey-400:                      #cbd5e0;
$grey-300:                      #e2e8f0;
$grey-200:                      #edf2f7;
$grey-100:                      #f7fafc;
$grey-600:                      #718096;
$grey-700:                      #4a5568;
$grey-800:                      #2d3748;
$grey-900:                      #1a202c;

$grey-darker:                   #333;
$grey-dark:                     #777;
$grey:                          #aaa;
$grey-light:                    #ccc;
$grey-lighter:                  #e2e8f0;


/* UI color palette
   ========================================================================== */
$green:                         #0ACC73;
$lightgreen:                    lighten($green, 50%);

$teal-100:                      #f2f9fa;
$teal-200:                      #d8eff2;
$teal-light:                    #B8E2E7;
$teal-300:                      #B8E2E7;
$teal-400:                      #8acfd8;
$teal:                          #70C5D0;
$teal-500:                      #70C5D0;
$teal-600:                      #66b4be;
$teal-700:                      #5ca2ab;
$teal-800:                      #487e85;
$teal-900:                      #3e6c72;

$blue-500:                      #4299e1;

$orange:                        #D96436;
$red:                           #C60054;
$purple:                        #510CE8;
$pink:                          deeppink;
$navy:                          #004d66;
$navy-light:                    lighten($navy, 75%);


/* Brand color palette
   ========================================================================== */
$brand-primary-color:           $teal-500;


/* Font families
   ========================================================================== */
$working-font-family:           "Gotham SSm A", "Gotham SSm B",sans-serif;
$display-font-family:           'rift',sans-serif;


/* Global UI treatments
   ========================================================================== */
$global-text-color:             $grey-darker;
$global-heading-color:          #000;
$global-border-radius:          .25rem !default;
$global-transition:             all 300ms ease-in-out !default;
$global-input-height:           2.5rem;
$global-bold-weight:            700;
$global-normal-weight:          400;
$global-light-weight:           200;
$global-high-shadow:            0 3px 20px rgba(0,0,0,.1);


/* Background colors
   ========================================================================== */
$global-body-background:        $grey-200 !default;

/* UI Variables
   ========================================================================== */
$navbar-height:                 60px;
$navbar-background:             $grey-darker;
