/* ==========================================================================
   #RESET
   ========================================================================== */

/**
 * A very simple reset that sits on top of Normalize.css.
 */

body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin:  0;
  padding: 0;
}

button {
  background: transparent;
  border: 0;
  font-size: 1em;
  padding: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */

fieldset {
  border: 0;
  min-width: 0; /* [1] */
}

iframe {
  border: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */

[tabindex="-1"]:focus {
  outline: none !important;
}


/**
 * Remove trailing margins from nested lists.
 */

li > {

  ol,
  ul {
    margin-bottom: 0;
  }

}


/**
 * Remove default table spacing.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:focus {
  outline: none;
}
