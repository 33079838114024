/**
 * simplified 'media object' for one thumbnail and a single line of text to the
 * right
 **/

.c-Slat {
  align-items: center;
  color: $global-text-color;
  display: flex;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    .c-Slat-text {
      text-decoration: underline;
    }
  }
}

.c-Slat-thumb {
  margin-right: .833em;
}

.c-Slat-text {
  flex: 1;
  font-size: .833em;
}
