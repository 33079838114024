.c-payform__fieldset {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
  border-radius: 4px;
  margin-bottom: 1rem;
}
.c-payform__fieldset-inner {
  align-items: center;
  display: flex;
  padding: 1rem 0;
}
.c-payform__fieldset-label {
  font-size: .875rem;
  padding: 0 1em;
  text-align: right;
  width: 100px;
}
.c-payform__card-wrapper {
  flex: 1;
  padding-left: 1rem;
  position: relative;
}
.c-payform__card-brand,
.c-payform__card-wrapper {
  align-items: center;
  display: flex;
}
.c-payform__card-brand {
  width: 1.5rem;
}

.c-payform__card-brand-front {
  display: block;
}

.c-payform__card-brand-back {
  display: none;
}

.c-payform__card {
  display: flex;
  flex: 1;
  margin: 0 1rem;
}
.c-payform__card-item {
  margin-right: 1rem;
}
.c-payform__input{
  border: none;
  border-bottom: 1px solid $grey-400;
  border-radius: 0;
  font-size: 1em;
  height: 2.5rem;
  padding: 0;
}
.c-payform__card-number {
  flex: 8;
}
.c-payform__card-expiry{
  flex: 3;
}
.c-payform__card-cvc {
  flex: 2;
  margin-right: 0;
}

@media (max-width: 560px) {
  .c-payform__fieldset--payment .c-payform__fieldset-label {
    display: none;
  }
}
@media (max-width: 360px) {
  .c-payform__card-wrapper { padding-left: 0; }
  .c-payform__card-brand { display: none; }
}
