.c-DivvyWorkflow {
  counter-reset: workflow-counter;
  display: block;
  margin: 0 auto;
  padding: 0;
}

.c-DivvyWorkflow-item {
  color: $grey-dark;
  flex: 1;
  font-size: 1.2em;
  margin: 0 auto 2.986em;
  max-width: 320px;
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &::before {
    color: $teal;
    content: counter(workflow-counter, decimal-leading-zero) '.';
    counter-increment: workflow-counter;
    font-size: 1.728em;
    font-weight: $global-bold-weight;
    position: relative;
    z-index: 1;
  }

  &::after {
    border: 10px solid $grey-100;
    border-radius: 100%;
    content: '';
    height: 7.43em;
    left: -1.728em;
    position: absolute;
    top: -1.44em;
    width: 7.43em;
    z-index: 0;
  }
}

@include mq($from: md) {
  .c-DivvyWorkflow {
    display: flex;
    justify-content: space-around;
    margin: 0 -#{$global-spacing-unit};
    max-width: none;
  }

  .c-DivvyWorkflow-item {
    display: flex;
    flex-flow: column wrap;
    margin: 0;
    padding: 0 $global-spacing-unit;

    > * {
      align-items: flex-start;
    }

    &::after {
      height: 8.916em;
      width: 8.916em;
    }
  }

  .c-DivvyWorkflow-figure {
    margin-top: auto;
  }
}
