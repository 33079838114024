/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Redefine all of our basic heading styles against utility classes so as to
 * allow for double stranded heading hierarchy, e.g. we semantically need an H2,
 * but we want it to be sized like an H1:
 *
 *   <h2 class="u-h1"></h2>
 *
 */

.u-h1 {
  @include font-size($font-size-h1, $important: true);
}

.u-h2 {
  @include font-size($font-size-h2, $important: true);
}

.u-h3 {
  @include font-size($font-size-h3, $important: true);
}

.u-h4 {
  @include font-size($font-size-h4, $important: true);
}

.u-h5 {
  @include font-size($font-size-h5, $important: true);
}

.u-h6 {
  @include font-size($font-size-h6, $important: true);
}

.u-h7 {
  font-size: .833em !important;
}
