/* =============================================================================
   #COLLAGE
   ========================================================================== */

.c-Collage {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
}

@keyframes lightningBugs {
  0% {
    opacity: .60;
  }

  11% {
    opacity: 1;
  }
  22% {
    opacity: .60;
  }
}

.c-Collage-item {
  align-items: flex-start;
  background: none;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.c-Collage--lightningBugs {
  .c-Collage-item {
    animation: lightningBugs 27s ease-out infinite running;
    opacity: .60;
  }

  .c-Collage:hover {
    .c-Collage-item {
      animation: none;
      opacity: .60;

      &:hover {
        opacity: 1;
      }
    }
  }

  .c-Collage-item:nth-child(2) {
    animation-delay: 3s;
  }

  .c-Collage-item:nth-child(3) {
    animation-delay: 6s;
  }

  .c-Collage-item:nth-child(4) {
    animation-delay: 9s;
  }

  .c-Collage-item:nth-child(5) {
    animation-delay: 12s;
  }

  .c-Collage-item:nth-child(6) {
    animation-delay: 15s;
  }

  .c-Collage-item:nth-child(7) {
    animation-delay: 18s;
  }

  .c-Collage-item:nth-child(8) {
    animation-delay: 21s;
  }

  .c-Collage-item:nth-child(9) {
    animation-delay: 24s;
  }
}
