.c-PageNav {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0,0,0,.1);
}

.c-PageNav-list {
   margin: 0;
}

.c-PageNav-item {
  border-bottom: 1px solid $grey-lighter;
  margin-bottom: 1px;
  padding: 9px 1em;
}

.c-PageNav-action {
  text-decoration: none;
}
