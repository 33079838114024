/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
}


/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:before {
  bottom: 100%;
  left: calc(0 + 6px);
  width: 0;
  border-top: 5px solid rgba($grey-800, 90%);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Position tooltip above the element */
[data-tooltip]:after {
  background-color: rgba($grey-800, 90%);
  border-radius: .25rem;
  bottom: 100%;
  color: #fff;
  content: attr(data-tooltip);
  font-size: .75rem;
  left: 0;
  line-height: 1.25;
  margin-bottom: 5px;
  max-width: 200px;
  padding: 7px;
  text-align: center;
  white-space: nowrap;
}

/* Show tooltip content on hover */
[data-tooltip] {
  &:hover,
  &:focus {
    &:before,
    &:after {
      visibility: visible;
      opacity: 1;
      transform: translate(0, -.25rem);
    }
  }
}

/* Right */
.c-tooltip--right {
  &:before,
  &:after {
    bottom: 50%;
    left: 100%;
    transform: translate(0, 50%);
  }

  &:before {
    margin-bottom: 0;
    margin-left: 0;
    border-bottom: 5px solid transparent;
    border-left: none;
    border-top-color: transparent;
    border-right-color: #000;
    border-right-color: hsla(0, 0%, 20%, 0.9);
  }

  &:after {
    margin-bottom: 0;
    margin-left: 5px;
  }

  &:hover,
  &:focus {
    &:before,
    &:after {
      transform: translate(.25rem, 50%);
    }
  }
}
