/* ==========================================================================
   #BLOCK
   ========================================================================== */

/**
 * Stacked image-with-text object. A simple abstraction to cover a very commonly
 * occurring design pattern.
 */

.o-block {
  display: block;
}


.o-block__image {
  margin-bottom: $global-spacing-unit;

  /* Size variants.
   ====================================================================== */

  .o-block--flush > &,
  .o-block--overlap > & {
    margin-bottom: 0;
  }

  .o-block--tiny > & {
    margin-bottom: $global-spacing-unit-xs;
  }

  .o-block--small > & {
    margin-bottom: $global-spacing-unit-sm;
  }

  .o-block--large > & {
    margin-bottom: $global-spacing-unit-lg;
  }

  .o-block--huge > & {
    margin-bottom: $global-spacing-unit-xl;
  }
}


.o-block__text {
  display: block;
  position: relative;
}



/* Alignment variants.
   ========================================================================== */

.o-block--right {
  text-align: right;
}

.o-block--left {
  text-align: left;
}

.o-block--overlap {
  .o-block__image + .o-block__text {
    margin-top: -40px;
  }
}
