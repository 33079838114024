.c-Toggle-control {
  display: none;
}

.c-Toggle-label {
  color: $grey-dark;
  display: block;
  line-height: 1;
  position: relative;
  white-space: nowrap;

  &::before {
    border: 2px solid;
    content: '';
    display: inline-block;
    height: 16px;
    margin-right: 4px;
    vertical-align: top;
    width: 16px;
  }

  .c-Toggle-control:checked + & {
    color: #000;

    &::before {
      border-color: $brand-primary-color;
    }

    &::after {
      background: $brand-primary-color;
      content: '';
      height: 8px;
      left: 4px;
      position: absolute;
      top: 4px;
      width: 8px;
    }
  }
}
