.c-Tag {
  box-shadow: 0 1px 0 rgba(255,255,255,.75);
  display: block;
  border: 1px solid $grey-300;
  color: $grey-dark;
  margin: 0 4px 4px 0;
  padding: 4px 8px;
  text-decoration: none;
  transition: all .3s ease;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active {
    background: #fff;
    border-bottom-color: $grey-600;
    box-shadow: $global-high-shadow;
  }
}
