/* =============================================================================
   #CARD
   ========================================================================== */
/**
 * Basic card component
 */

/**
 * 1. Account for browser defaults of elements that might be the root node of
 *    the component.
 */

.c-Card {
  background: #fff;
  color: $global-text-color;
  display: flex; /* 1 */
  flex-direction: column;
  text-decoration: none; /* 1 */
}

/**
 * 1. If a collection of cards are presented horizontally, this will provide
 *    equal height support.
 */
.c-Card--equalHeight {
  height: 100%; /* 1 */
}

/**
 * 1. Because the card could potentially be expanding its height to 100%, we'll
 *    make the card header expand to fit any extra space not used by the card
 *    body.
 */
.c-Card-header {
  flex: 1; /* 1 */
  padding: $global-spacing-unit;
}
