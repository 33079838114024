/**
 * Theming classes to go on to of default Owl Carousel styling
 */

.c-carousel {
  position: relative;

  .owl-nav {
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 3rem 1rem;
    position: absolute;
    width: 100%;

    .disabled {
      cursor: default;
      opacity: 0;
    }

    button[class*='owl-'] {
      align-items: center;
      background: url('/assets/img/arrow-left.svg') no-repeat left center;
      background-size: 1rem;
      display: flex;
      font-size: .875rem;
      position: relative;
      padding-left: 1.25rem;
      text-transform: uppercase;
    }

    button.owl-next {
      background-image: url('/assets/img/arrow-right.svg');
      background-position: right center;
      flex-direction: row-reverse;
      padding-left: 0;
      padding-right: 1.25rem;
    }
  }
}

.c-carousel--no-hide {
  .owl-stage-outer {
    overflow: visible;
  }
}
