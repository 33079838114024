/**
 * Dependent on slick-slider js plugin
 */

.c-workflow {
  counter-reset: item;

  @include mq($from: md) {
    display: flex;
  }
}

.c-workflow__item {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
  flex: 1;
  height: auto;
  margin: 0 0 1rem 5rem;
  padding: 2rem;
  position: relative;

  &::before {
    content: counter(item);
    counter-increment: item;
    font-family: $display-font-family;
    font-size: 125px;
    font-weight: 700;
    left: -4rem;
    line-height: 1;
    position: absolute;
    text-align: center;
    top: 1rem;
    width: 3rem;
  }

  @include mq($from: md) {
    padding: 4.3rem 2.986rem;
    margin: 0 1rem;

    &::before {
      left: -.2em;
    }
  }

  @include mq($from: lg) {
    &::before {
      font-size: 200px;
      width: 72px;
    }
  }
}

.c-workflow__chevron {
  position: absolute;
  left: -3.5rem;
  top: 0;
  width: 2rem;

  @include mq($from: lg) {
    left: calc(-64px - 2rem);
    top: 64px;
  }
}
