.o-account {
  display: flex;
  flex-direction: column;
}

.o-account__sidebar,
.o-account__main {
  padding: 1rem;
}

.o-account__nav {
  bottom: 0;
  position: sticky;
  width: 100%;
  z-index: 900;
}

@include mq($from: sm) {
  .o-account__nav {
    border-bottom: 1px solid $grey-light;
    order: -1;
    position: relative;
    z-index: 0;
  }
}

@include mq($from: 720px) {
  .o-account__body {
    display: flex;
  }

  .o-account__sidebar,
  .o-account__main {
    padding: 2rem 1rem;
  }

  .o-account__sidebar {
    border-right: 1px solid $grey-light;
    width: 300px;
  }

  .o-account__main {
    flex: 1;
  }
}

@include mq($from: lg) {
  .o-account__sidebar {
    width: 320px;
  }
}
