.c-message-bar {
  background: #fff;
  color: $teal;
  font-size: .694em;
  font-weight: $global-bold-weight;
  padding: 9px;
  text-align: center;
  text-transform: uppercase;

  p {
    margin: 0;
  }
}
