.c-alert {
  background: #fff;
  border: 2px solid transparent;
  padding: 1rem;
}

.c-alert--success {
  background: $lightgreen;
  color: $green;
}

.c-alert--notice {
  background: lightgoldenrodyellow;
  color: darkgoldenrod;
}
