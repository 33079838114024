.c-table {
  border-collapse: separate;
  border-spacing: 0 .579rem;

  tr {
    box-shadow: 0 2px 4px rgba(0,0,0,.05);
  }

  td,
  th {
    background: #fff;
    border: none;
  }
}
