.o-NavList {
  display: flex;
  font-size: .833em;
}

.o-NavList-item {
  align-items: center;
  display: flex;
}

.o-NavList-action {
  color: currentColor;
  opacity: 1;
  padding: 9px;
  text-decoration: none;
  transition: all .1s ease-in;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

@include mq($from: sm) {
  .o-NavList {
    font-size: 1em;
  }
}
