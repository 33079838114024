/**
 * <div class="o-Content">
 *   <div class="o-Content-header">
 *     <div class="o-Content-main">
 *     </div>
 *   </div>
 *
 *   <div class="o-Content-body">
 *     <div class="o-Content-main">
 *     </div>
 *
 *     <div class="o-Content-secondary">
 *     </div>
 *   </div>
 * </div>
 */

.o-ContentHead,
.o-ContentBody {
  padding: 2.074em 9px;
}

.o-Content-main {
  flex: 1;
}

.o-Content-aside {
  border-top: 1px solid $grey-lighter;
  margin-top: $global-spacing-unit;
  padding-top: $global-spacing-unit;
}

.o-Content--page {
  .o-ContentHead {
    background: $teal;
    color: #fff;
  }
}

@include mq($from: md) {
  .o-ContentHead,
  .o-ContentBody {
    padding: 2.986em 1em;
  }
}

@include mq($from: 50em) {
  .o-Content {
    .o-Container {
      display: flex;
    }
  }

  .o-Content-aside {
    border-left: 1px solid $grey-lighter;
    border-top: none;
    margin: 0 0 0 $global-spacing-unit;
    padding: 0 0 0 $global-spacing-unit;
    width: calc(300px + #{$global-spacing-unit});
  }
}
