.c-ButtonGroup {
  display: flex;
  flex-wrap: wrap;

  .c-Button {
    align-items: center;
    display: flex;
    margin-right: 4px;
  }
}

/** @define Button */

/**
 * The button classes are best applied to links and buttons.
 * These components can be used in forms, as calls to action, or as part of the
 * general UI of the site/app.
 */

/**
 * 1. Inherit font styles from ancestor.
 * 2. Prevent button text from being selectable.
 */

.c-Button,
.c-button {
  background: none;
  border-color: transparent;
  border-radius: $global-border-radius;
  border-style: solid;
  border-width: 2px;
  color: inherit; /* 1 */
  display: inline-block;
  font: inherit; /* 1 */
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  padding: .694em 1em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: .1s;
  user-select: none; /* 2 */
  white-space: nowrap;
}

.c-Button--primary,
.c-button--primary {
  background: $brand-primary-color;
  color: #fff;

  &:not([disabled]):hover,
  &:not([disabled]):focus, {
    background: none;
    border-color: $brand-primary-color;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
    color: $brand-primary-color;
  }

  &:active {
    box-shadow: none;
    transform: scale(.98);
  }
}


/**
 * Modifier: button that will potentially remove or delete something
 */
.c-button--danger {
  background: $orange;
  color: #fff;
}


/**
 * Modifier: inverse colors than the primary button above
 */
.c-Button--inverse {
  background: #fff;
  color: $teal;
}

.c-button--light {
  background: $grey-lighter;
}

/**
 * Modifier: outlined button
 */

.c-button--outlined {
  background: none;
  border-color: inherit
}

/**
 * Mofidier: compact button
 */
.c-button--compact {
  padding: .402em .694em;
}

/**
 * Modifier: no padding or borders
 */
.c-button--plain {
  border: none;
  padding: 0;
}

/**
 * Elements: icon included with text
 */
.c-button__icon {
  flex-shrink: 0; /* don't allow it to shrink */
  transition: all .3s ease-in;
  width: 1em;

  + .c-button__text {
    margin-left: .579rem;
  }

  .c-button__text + & {
    margin-left: .579rem;
  }
}

.c-button--slide-icon {
  &:hover,
  &:focus,
  &:active {
    .c-button__icon {
      transform: translateX(8px);
    }
  }
}

/**
 * Remove excess padding and border in Firefox 4+
 */

.c-Button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

.c-Button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/**
 * UI states
 */

.c-Button:hover,
.c-Button:focus,
.c-Button:active {
  text-decoration: none;
}

.c-Button:disabled,
.c-Button.is-disabled {
  cursor: default;
  opacity: 0.6;
}

@keyframes loading {
  from {
    transform-origin: center;
    transform: rotate(0deg);
  }

  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
}
.c-button--loading {
  &,
  &:disabled {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: loading;
    background: transparent;
    border: .5rem solid $teal-light;
    border-radius: 999px;
    border-top: .5rem solid $teal;
    height: 2rem;
    opacity: 1;
    overflow: hidden;
    padding: 0;
    text-indent: 2rem;
    width: 2rem;
  }
}

.c-cart-button {
  position: relative;
}

.c-cart-button--active {
  &::after {
    background: $teal;
    border-radius: 8px;
    content: '';
    display: block;
    height: 8px;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-indent: 100px;
    top: -4px;
    white-space: nowrap;
    width: 8px;
  }
}
