summary {
  cursor: pointer;
  list-style: none;
  padding-right: 2rem;
  position: relative;

  &::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PHBvbHlnb24gcG9pbnRzPSI5LjI5MyAxMi45NSAxMCAxMy42NTcgMTUuNjU3IDggMTQuMjQzIDYuNTg2IDEwIDEwLjgyOCA1Ljc1NyA2LjU4NiA0LjM0MyA4Ii8+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 18px;
    opacity: .75;
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
  }

  &::-webkit-details-marker {
    display: none;
  }

  details[open] & {
    margin-bottom: .5rem;

    &:after {
      opacity: 1;
      transform: rotate(180deg);
    }
  }
}
