.u-animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.u-animated.u-infinite {
  animation-iteration-count: infinite;
}

@keyframes fadeIn{
  from { opacity: 0; }
  to { opacity: 1; }
}
.u-fade-in{
  animation-name: fadeIn;
}

@keyframes slideOutLeft{
  0%{
    transform:translateZ(0);
  }
  to {
    visibility:hidden;
    transform:translate3d(-100%,0,0);
  }
}
.u-slide-out-left{
  animation-name:slideOutLeft;
}

@keyframes slideOutRight {
  0% {
    transform:translateZ(0)
  }
  to {
    visibility:hidden;transform:translate3d(100%,0,0)
  }
}
.u-slide-out-right{
  animation-name:slideOutRight;
}

@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate(0deg);
  }

  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
}
.u-rotate-in {
  animation-name: rotateIn;
}
