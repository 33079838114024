.u-absolute { position: absolute; }
.u-relative { position: relative; }
.u-fixed { position: fixed; }

@include mq($from: sm) {
  .sm\:u-absolute { position: absolute; }
  .sm\:u-relative { position: relative; }
}

@include mq($from: md) {
  .md\:u-absolute { position: absolute; }
  .md\:u-relative { position: relative; }
}

.u-inset-0 { bottom: 0; left: 0; right: 0; top: 0; }
.u-pin-t,
.u-top-0 { top: 0;}
.u-pin-r,
.u-right-0 { right: 0; }
.u-pin-b,
.u-bottom-0 { bottom: 0; }
.u-pin-l,
.u-left-0 { left: 0; }

.u-top-1\/4 { top: 25%; }
.u-top-2\/4 { top: 50%; }
.u-top-3\/4 { top: 75%; }

.u-pin-r-40 { right: 40%; }

.u-translate-r-full { transform: translateX(100%); }
.u-translate-b-full { transform: translateY(100%); }
.u-translate-l-full { transform: translateX(-100%); }

@include mq($from: sm) {
  .sm\:u-left-auto { left: auto; }
}

@include mq($from: md) {
  .md\:u-pin-none {
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
  }

  .md\:u-bottom-0 { bottom: 0; }
}

@include mq($from: lg) {
  .lg\:u-bottom-0 { bottom: 0; }
}

/* Z-Index
   ========================================================================== */
.u-z-0 { z-index: 0; }
.u-z-10 { z-index: 10; }
.u-z-20 { z-index: 20; }
.u-z-30 { z-index: 30; }
.u-z-40 { z-index: 40; }
.u-z-50 { z-index: 50; }
.u-z-auto { z-index: auto; }

@include mq($from: sm) {
  .sm\:u-top-auto { top: auto; }

  .sm\:u-top-1\/4 { top: 25%; }
  .sm\:u-right-0 { right: 0; }
  .sm\:u-bottom-0 { bottom: 0; }
}

@include mq($from: md) {
  .md\:u-right-0 { right: 0; }
  .md\:u-bottom-0 { bottom: 0; }
}
