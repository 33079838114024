@mixin font-size($font-size, $line-height: normal, $important: false) {
  @if ($important == true) {
    $important: !important;
  } @elseif ($important == false) {
    $important: null;
  } @else {
    @error "`#{$important}` needs to be `true` or `false`."
  }

  font-size: ($font-size / $global-font-size) * 1rem $important;
  line-height: $line-height $important;
}
