/* ==========================================================================
   #COLLECTION
   ========================================================================== */

/**
 * Core collection component
 *
 */

/* Collection container
   ========================================================================== */

/**
 * All content must be contained within child `o-Collection-item` elements.
 *
 * 1. Account for browser defaults of elements that might be the root node of
 *    the component.
 */

.o-Collection {
  display: grid; /* 1 */
  grid-gap: $global-spacing-unit;
  grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
  padding: 0; /* 1 */
}

/**
 * Modifier: all cells match height of tallest cell in a row
 */

.o-Collection > .o-Collection-item {

}
