/**
 * The list-inline object simply displays a list of items in one line.
 */

.o-ListInline {
  display: flex;
  list-style: none;
  margin-left: 0;
}

.o-ListInline-item {
  margin-right: 8px;
}
