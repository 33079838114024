/* ==========================================================================
   #MEDIA
   ========================================================================== */

/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */

.o-media {
  align-items: flex-start;
  display: flex;
}

.o-media__image {
  margin-right: $global-spacing-unit;
}


.o-media__body {
  flex: 1;

  &,
  > :last-child {
    margin-bottom: 0;
  }
}





/* Size variants.
   ========================================================================== */

/**
 * Modify the amount of space between our image and our text. We also have
 * reversible options for all available sizes.
 */


/* Gutterless media objects.
  ========================================================================== */

.o-media--flush {

  > .o-media__image {
    margin-right: 0;
    margin-left: 0;
 }

}


.o-media--xs {

  > .o-media__image {
    margin-right: $global-spacing-unit-xs;
  }

  &.o-media--reverse {

    > .o-media__image {
      margin-right: 0;
      margin-left: $global-spacing-unit-xs;
    }

  }

}


.o-media--sm {

  > .o-Media-img {
    margin-right: $global-spacing-unit-sm;
  }

  &.o-media--reverse {

    > .o-Media-img {
      margin-right: 0;
      margin-left: $global-spacing-unit-sm;
    }

  }

}


.o-media--lg {

  > .o-media__image {
    margin-right: $global-spacing-unit-lg;
  }

  &.o-media--reverse {

    > .o-media__image {
      margin-right: 0;
      margin-left: $global-spacing-unit-lg;
    }

  }

}


.o-media--xl {

  > .o-media__image {
    margin-right: $global-spacing-unit-xl;
  }

  &.o-Media--reverse {

    > .o-media__image {
      margin-right: 0;
      margin-left: $global-spacing-unit-xl;
    }

  }

}



/* Reversed media objects.
   ========================================================================== */

.o-media--reverse {

  > .o-media__image {
    float: right;
    margin-right: 0;
    margin-left: $global-spacing-unit;
  }
}



/* Media object alignments.
   ========================================================================== */

.o-media--align-middle {
  align-items: center;
}
