.c-jumbotron-bg {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.c-jumbotron-bg__img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

@include mq($from: md) {
  .c-jumbotron-bg__img {
    opacity: 1;
  }
}
