.o-viewport {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.o-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0; /* slick slider hack to keep it contained */

  > main {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.o-page__header {
  padding-left: 1rem;
  padding-top: 1rem;
}

.c-sidebar__toggle {
  position: relative;
}

.c-sidebar__container {
  background: #fff;
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  left: -260px;
  max-width: 260px;
  padding-top: 1em;
  position: fixed;
  top: 0;
  transition: .2s;
  width: 90%;
  z-index: 1000;

  .has-sidebar-expanded & {
    box-shadow: 2px 0 4px rgba(0,0,0,.1);
    left: 0;
  }
}

.c-sidebar__nav {
  padding: 1rem;

  a {
    align-items: center;
    color: inherit;
    display: flex;
    margin-bottom: 1rem;
    text-decoration: none;
  }
}

.c-curtain-toggle {
  background: rgba(0,0,0,.5);
  display: block;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  z-index: 950;

  .js-drawer-open &,
  .has-sidebar-expanded & {
    opacity: 1;
    width: 100%;
  }
}

@include mq($from: 50em) {
  .o-page {

  }
}

@include mq($from: lg) {
  .o-page {

  }
}
