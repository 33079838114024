.c-Faceting {
  margin-bottom: 2.074em;
}

.c-Faceting-title {
  font-size: 1.2em;
  font-weight: $global-normal-weight;
  margin-bottom: .833rem;
}

.c-Faceting-facet {
  margin-bottom: .833rem;
}

#facet-type {
  display: none;
}
