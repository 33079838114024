/* hidden checkbox */
.c-menu-toggle { display: none; }

.c-menu {
  position: relative;
}

/* label element around a menu icon */
.c-menu-button {
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 70%;
  position: relative;
  width: 20px;

  span {
    background: currentColor;
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    transition: all .3s ease;
    width: 100%;
    will-change: transform;

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      bottom: 0;
      top: auto;
    }
  }

  :target &,
  &.is-toggled {
    span:nth-child(1) {
      left: 3px;
      transform: rotate(45deg);
      transform-origin: left center;
    }
    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      bottom: auto;
      left: 3px;
      top: 100%;
      transform: rotate(-45deg);
      transform-origin: left center;
    }
  }
}


/* Menu
   ========================================================================== */
.c-menu {
  overflow-y: auto;
}

.c-menu__inner {
  padding: 1rem;
}

.c-menu__account,
.c-menu__title {
  border-bottom: 1px solid rgba(#fff,.15);
}

.c-menu__links {
  display: flex;
  flex-direction: column;
  font-family: $display-font-family;
  font-weight: $global-bold-weight;

  a { color: currentColor; }
}

.c-menu__item {
  font-size: 1rem;
}

.c-menu__item--active {
  background: $grey-lighter;
}

.c-menu__item-icon {
  margin-right: 4px;
}

.c-menu__action {
  color: inherit;
  display: block;
  padding: .279em 0;
  text-decoration: none;
}

.c-menu__board {
  transition: all .3s ease;
}

.c-menu--lg {
  .c-menu__action {
    font-size: 1.44rem;
  }
}
