/* A simple type of carousel that uses arrows to page through slides */

/* This component relies on the Slick Slider jquery carousel plugin */

.c-Pager {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  li {
    padding-right: 8px;
  }

  .slick-arrow {
    background: #fff;
    overflow: hidden;
    position: relative;
    text-indent: 100%;
    width: 44px;

    &::before {
      border-left: 3px solid $brand-primary-color;
      border-bottom: 3px solid $brand-primary-color;
      content: '';
      height: 12px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-4px, -50%) rotate(45deg);
      width: 12px;
    }
  }

  .slick-next {
    &::before {
      transform: translate(-8px, -50%) rotate(-135deg);
    }
  }

  .slick-list {
    flex: 1;
  }
}
