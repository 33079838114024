.u-shadow { box-shadow: 0 2px 4px rgba(0,0,0,.1); }

.u-shadow-md {
  box-shadow:
    0 4px 8px 0 rgba(0,0,0,0.12),
    0 2px 4px 0 rgba(0,0,0,0.08);
}

.u-shadow-lg {
  box-shadow:
    0 15px 30px 0 rgba(0,0,0,0.11),
    0 5px 15px 0 rgba(0,0,0,0.08);
}
