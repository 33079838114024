.u-block{ display:block }
.u-inline-block { display: inline-block; }
.u-flex{ display:flex }
.u-inline-flex { display: inline-flex; }
.u-inline{ display:inline }
.u-hidden{ display:none }

@include mq($from: sm) {
  .sm\:u-flex{ display:flex }
  .sm\:u-inline { display: inline; }
  .sm\:u-inline-block{ display:inline-block }
  .sm\:u-hidden { display: none; }
}

@include mq($from: md) {
  .md\:u-block { display:block; }
  .md\:u-flex { display: flex; }
  .md\:u-inline { display: inline; }
  .md\:u-inline-block { display: inline-block; }
  .md\:u-hidden { display: none; }
}

@include mq($from: lg) {
  .lg\:u-block{ display:block }
  .lg\:u-flex{ display: flex; }
  .lg\:u-inline-block { display: inline-block; }
  .lg\:u-hidden{ display:none }
}
