.c-asset-array {
  overflow: hidden;
  position: relative;
}

.c-asset-array__media {
  height: 240px;
  object-fit: cover;
  width: 360px;
  z-index: 10;

  &:first-child {
    bottom: 0;
    position: absolute;
    left: 50%;
  }

  &:nth-child(2n) {
    left: 25%;
    position: absolute;
    top: 0;
  }

  &:nth-child(3n) {
    margin: 6rem 0 6rem 2%;
    position: relative;
  }
}

.c-asset-array__play {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.c-asset-array__pattern {
  height: 16rem;
  position: absolute;

  &:first-child {
    left: 0;
    top: 1rem;
    width: 25%;
    z-index: 0;
  }

  &:nth-child(2n) {
    right: -2%;
    top: 6rem;
    width: 50%;
  }

  &:nth-child(3n) {
    bottom: .5rem;
    height: 8rem;
    left: 25%;
    width: 25%;
  }
}

@include mq($from: lg) {
  .c-asset-array__media {
    height: 320px;
    width: 480px;
  }
}

@include mq($from: xl) {
  .c-asset-array {
    overflow: visible;
  }

  .c-asset-array__media {
    &:first-child {
      left: auto;
      right: 0;
    }
  }
}
