.c-initials-block {
  align-items: center;
  background: #000;
  border-radius: 999px;
  color: #fff;
  display: flex;
  font-size: 1em;
  height: 2.5em;
  justify-content: center;
  width: 2.5em;
}

.c-initials-block--inverse {
  background: $grey-lighter;
  color: $grey-darker;
}
