$footer-background:           #000;

.c-Footer {
  background: $footer-background;
  color: #fff;
  padding: $global-spacing-unit $global-spacing-unit 4rem;

  a {
    color: #fff;
    text-decoration: none;
  }

  .c-Nav-action {
    text-decoration: underline;
    text-transform: none;
  }
}

.c-Footer-topTier,
.c-Footer-bottomTier {
  display: flex;
  flex-flow: column nowrap;

  > * {
    flex: 1;

    > :last-child {
      margin-bottom: 0;
    }
  }
}

.c-Footer-bottomTier {
  border-top: 1px solid lighten($footer-background, 15%);
  color: $grey-light;
  margin-top: $global-spacing-unit;
}

.c-Footer-loggedin {
  padding: .335em 0;
}

.c-Footer-pitch,
.c-Footer-nav,
.c-Footer-powered {
  padding-top: $global-spacing-unit;
}

.c-Footer-nav {
  flex: 2;
}

@include mq($from: md) {
  .c-Footer-topTier,
  .c-Footer-bottomTier {
    flex-direction: row;
  }

  .c-Footer-loggedin {
    text-align: right;
  }
}
