.c-donut-graph{
  color:#777;
  display:inline-block;
  font-size:6.19em;
  margin:0;
  max-width:100%;
  padding-bottom:100%;
  position:relative;width:480px
}
.c-donut-graph:before{
  border:35px solid #08c5d1;
  border-radius:50%;
  box-sizing:border-box;
  clip-path:polygon(50% 100%,50% 100%,100% 100%,100% 0,0 0,50% 50%);
  content:"";
  height:100%;
  left:50%;
  position:absolute;
  top:50%;
  transform:translate(-50%,-50%) rotate(45deg);
  transform-origin:center center;
  width:100%;
}
.c-donut-graph:after{
  content:"63%";
  display:block;
  font-family:rift,sans-serif;
  font-weight:700;
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
}
