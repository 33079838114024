/* ==========================================================================
   Size
   ========================================================================== */

/**
 * inuitcss generates a series of utility classes that give a fluid width to
 * whichever element they’re applied, e.g.:
 *
 *   <img src="" alt="" class="u-size1of2" />
 *
 * These classes are most commonly used in conjunction with our layout system,
 * e.g.:
 *
 *   <div class="u-w-1/2">
 *
 * By default, inuitcss will also generate responsive variants of each of these
 * classes by using your Sass MQ configuration, e.g.:
 *
 *   <div class="o-Grid-cell  u-size1of1  u-md-size1of2  u-lg-size1of3">
 *
 * Optionally, inuitcss can generate offset classes which can push and pull
 * elements left and right by a specified amount, e.g.:
 *
 *   <div class="o-Grid-cell  u-size2of3  u-before1of3">
 *
 * This is useful for making very granular changes to the rendered order of
 * items in a layout.
 *
 * N.B. This option is turned off by default.
 */





// Which fractions would you like in your grid system(s)? By default, inuitcss
// provides you fractions of one whole, halves, thirds, quarters and fifths,
// e.g.:
//
//   .u-size1of2
//   .u-size2of5
//   .u-size3of4
//   .u-size2of3

$fractions: 1 2 3 4 5 6 !default;





// Optionally, inuitcss can generate classes to offset items by a certain width.
// Would you like to generate these types of class as well? E.g.:
//
//   .u-before-1/3
//   .u-before-2/4
//   .u-before-1/5
//   .u-before-2/3

$offsets: true !default;





// By default, inuitcss uses fractions-like classes like `<div class="u-1/4">`.
// You can change the `/` to whatever you fancy with this variable.
$widths-delimiter: \/ !default;





// When using Sass-MQ, this defines the separator for the breakpoints suffix
// in the class name. By default, we are generating the responsive suffixes
// for the classes with a `@` symbol so you get classes like:
// <div class="u-3/12@mobile">
$widths-breakpoint-separator: \:;





// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths. E.g. to create thirds
// and quarters for a small breakpoint:
// sm:u-w-1/7
// @include widths(3 4, -sm);

@mixin widths($columns, $breakpoint: null) {

  // Loop through the number of columns for each denominator of our fractions.
  @each $denominator in $columns {

    // Begin creating a numerator for our fraction up until we hit the
    // denominator.
    @for $numerator from 1 through $denominator {

      // Build a class in the format `.u-[<breakpoint>]-size3of4`.
      .#{$breakpoint}u-w-#{$numerator}#{$widths-delimiter}#{$denominator} {
        width: ($numerator / $denominator) * 100%;
      }

      @if ($offsets == true) {

        // Build a class in the format '.u-[<breakpoint>]-before1of2'.
        .#{$breakpoint}u-before-#{$numerator}#{$widths-delimiter}#{$denominator} {
          margin-left: ($numerator / $denominator) * 100%;
        }

        // Build a class in the format '.u-[<breakpoint>]-after1of2'.
        .#{$breakpoint}u-after-#{$numerator}#{$widths-delimiter}#{$denominator} {
          margin-right: ($numerator / $denominator) * 100%;
        }

      }

    }

  }

}




/**
 * A series of width helper classes that you can use to size things like grid
 * systems. Classes take a fraction-like format (e.g. `.u-2/3`). Use these in
 * your markup:
 *
 * <div class="u-size7of12">
 *
 * The following will generate widths helper classes based on the fractions
 * defined in the `$fractions` list.
 */

@include widths($fractions);




/**
 * If we’re using Sass-MQ, automatically generate grid system(s) for each of our
 * defined breakpoints, and give them a Responsive Suffix, e.g.:
 *
 * <div class="u-3/12@mobile">
 */

@if (variable-exists(mq-breakpoints)) {

  @each $bp-name, $bp-value in $mq-breakpoints {

    @include mq($from: $bp-name) {
      @include widths($fractions, #{$bp-name}#{$widths-breakpoint-separator});
    }

  }

}

/* Width
   ========================================================================== */
.u-w-1 { width: .25rem; }
.u-w-2 { width: .5rem; }
.u-w-3 { width: .75rem; }
.u-w-4 { width: 1rem; }
.u-w-6 { width: 1.5rem; }
.u-w-8 { width: 2rem; }
.u-w-10 { width: 2.5rem; }
.u-w-12 { width: 3rem; }
.u-w-16 { width: 4rem; }
.u-w-24 { width: 6rem; }
.u-w-32 { width: 8rem; }
.u-w-48 { width: 12rem; }
.u-w-64 { width: 16rem; }
.u-w-auto { width: auto; }
.u-w-full { width:100%; }


/* Min width
   ========================================================================== */
.u-min-w-0 { min-width: 0; }


/* Max width
   ========================================================================== */
.u-max-w-none { max-width: none; }
.u-max-w-xs { max-width: 20rem; }
.u-max-w-sm { max-width: 24rem; }
.u-max-w-md { max-width: 28rem; }
.u-max-w-lg { max-width: 32rem; }
.u-max-w-xl { max-width: 36rem; }
.u-max-w-2xl { max-width: 42rem; }
.u-max-w-3xl { max-width: 48rem; }
.u-max-w-4xl { max-width: 56rem; }
.u-max-w-5xl { max-width: 64rem; }
.u-max-w-6xl { max-width: 72rem; }

@include mq($from: sm) {
  .sm\:u-max-w-full { max-width: 100%; }
}

@include mq($from: md) {
  .md\:u-max-w-sm { max-width: 30rem; }
  .md\:u-max-w-full { max-width: 100%; }
}

@include mq($from: lg) {
  .lg\:u-max-w-full { max-width: 100%; }
}


/* Height
   ========================================================================== */
.u-h-1 { height: .25rem; }
.u-h-2 { height: .5rem; }
.u-h-3 { height: .75rem; }
.u-h-4 { height: 1rem; }
.u-h-6 { height: 1.5rem; }
.u-h-8 { height: 2rem; }
.u-h-10 { height: 2.5rem; }
.u-h-12 { height: 3rem; }
.u-h-16 { height: 4rem; }
.u-h-24 { height: 6rem; }
.u-h-32 { height: 8rem; }
.u-h-48 { height: 12rem; }
.u-h-64 { height: 16rem; }
.u-h-px { height: 1px; }
.u-h-screen { height: 100vh; }
.u-h-full { height: 100%; }

@include mq($from: sm) {
  .sm\:u-h-32 { height: 8rem; }
  .sm\:u-h-64 { height: 16rem; }
}


/* Min height
   ========================================================================== */
.u-min-h-screen { min-height:calc(100vh); }


/* Max height
   ========================================================================== */
.u-max-h-sm { max-height:20rem; }
.u-max-h-lg { max-height:50rem; }


@include mq($from: sm) {
  .sm\:u-w-6 { width: 1.5rem; }
  .sm\:u-w-16 { width: 4rem; }
  .sm\:u-w-24 { width: 6rem; }
}

@include mq($from: md) {
  .md\:u-max-w-4xl { max-width: 56rem; }
  .md\:u-max-w-none { max-width: none; }

  .md\:u-w-sidebar { width: 300px; }

  /* height */
  .md\:u-h-auto { height: auto; }
  .md\:u-h-full { height: 100%; }
}

@include mq($from: lg) {
  .lg\:u-w-sidebar { width: 300px; }

  .lg\:u-max-w-2xl { max-width: 70rem; }
}
