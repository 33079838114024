.o-Tags {
  display: flex;
  flex-wrap: wrap;
  font-size: .833em;
}

@include mq($from: md) {
  .o-Tags {
    font-size: 1em;
  }
}
