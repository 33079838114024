.c-ProductLanding-text,
.c-ProductLanding-figure {
  padding: $global-spacing-unit 0;
}

.c-ProductLanding-text {
  padding: 2.986em 9px $global-spacing-unit;
}

.c-ProductLanding-figure {
  align-items: center;
  display: flex;
  padding-bottom: 0;
}

.c-ProductLanding-artwork {
  display: block;
  margin: 0 auto;
}

@include mq($from:md) {
  .c-ProductLanding {
    display: flex;
  }

  .c-ProductLanding-text,
  .c-ProductLanding-figure {
    flex: 1;
  }

  .c-ProductLanding-text {
    margin-right: auto;
    max-width: 40em;
  }

  .c-ProductLanding-figure {
    max-width: 50%;
  }

  .c-ProductLanding-artwork {
    margin-bottom: -25%;
  }
}
