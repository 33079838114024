.c-features-hero {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, min-content);
}

.c-features-hero__text {
  padding: 2rem 0;
}

.c-features-hero__media,
.c-features-hero__figure {
  grid-column: 1;
  grid-row: 2;
}

.c-features-hero__figure {
  align-self: end;
  padding-left: 1rem;
}

.c-features-hero__pattern {
  height: 4rem;
}

@include mq($from: sm) {
  .c-features-hero {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, min-content);
  }

  .c-features-hero__text {
    grid-column: 1 / 6;
    grid-row: 1;
    z-index: 30;
  }

  .c-features-hero__media {
    grid-column: 4 / -1;
    grid-row: 1;
    z-index: 10;
  }

  .c-features-hero__figure {
    grid-column: 1 / -1;
    grid-row: 2;
    padding-left: 0;
    z-index: 20;
  }

  .c-features-hero__pattern {
    grid-column: 3 / -1;
    grid-row: 2;
    height: 14rem;
    position: relative;
    top: -8rem;
  }
}

@include mq($from: md) {
  .c-features-hero {
    grid-template-columns: repeat(12, 1fr);
  }

  .c-features-hero__text {
    grid-column-end: 9;
  }

  .c-features-hero__media {
    grid-column-start: 6;
  }
}

@include mq($from: lg) {
  .c-features-hero {
    grid-template-rows: 1fr 4rem 8rem;
  }
  .c-features-hero__text {
    align-self: start;
    grid-row: 1 / span 2;
    padding: 0;
  }

  .c-features-hero__media {
    grid-column-start: 5;
    grid-row: 1 / span 2;
  }

  .c-features-hero__figure {
    grid-row: 2 / 4;
    position: relative;
    top: -1rem;
  }

  .c-features-hero__pattern {
    grid-column: 3 / span 8;
    grid-row: 2 / 4;
    top: 0;
  }
}

@include mq($from: xl) {
  .c-features-hero__media {
    grid-column-start: 4;
  }

  .c-features-hero__figure {
    top: -2rem;
  }
}
