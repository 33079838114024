/* =============================================================================
   #FLEX
   ========================================================================== */

/**
 * Flexbox utilities
 */

/* Display
   ========================================================================== */
.u-flex { display: flex; }


/* Direction
   ========================================================================== */
.u-flex-row { flex-direction: row; }
.u-flex-row-reverse { flex-direction: row-reverse; }
.u-flex-col { flex-direction: column; }
.u-flex-col-reverse { flex-direction: column-reverse; }

/* Wrapping
   ========================================================================== */
.u-flex-no-wrap {flex-wrap: nowrap; }
.u-flex-wrap { flex-wrap:wrap; }

/* Justify content
   ========================================================================== */
.u-justify-center { justify-content: center; }
.u-justify-end { justify-content: flex-end; }
.u-justify-around { justify-content: space-around; }
.u-justify-between { justify-content: space-between; }

/* Align items
   ========================================================================== */
.u-items-start { align-items:flex-start; }
.u-items-center{ align-items:center; }
.u-items-end { align-items:flex-end; }

/* Flex, grow, & shrink
   ========================================================================== */
.u-flex-1 { flex:1; }
.u-flex-grow-2 { flex-grow: 2; }
.u-flex-no-shrink { flex-shrink: 0; }

/* Flex basis
   ========================================================================== */
.u-flex-basis-xs { flex-basis: 10rem; }
.u-flex-basis-sm { flex-basis: 15rem; }
.u-flex-basis-md { flex-basis: 20rem; }
.u-flex-basis-lg { flex-basis: 25rem; }
.u-flex-basis-xl { flex-basis: 30rem; }

/* Flex order
   ========================================================================== */
.u--order-1 { order: -1; }

@include mq($from: sm) {
  .sm\:u-flex-row { flex-direction: row; }
  .sm\:u-flex-col { flex-direction: column; }
  .sm\:u-items-center { align-items: center; }
}

@include mq($from: md) {
  .md\:u-flex-row { flex-direction: row; }
  .md\:u-flex-row-reverse { flex-direction: row-reverse; }
  .md\:u-flex-1 { flex: 1; }
  .md\:u-justify-start { justify-content: flex-start; }
  .md\:u-justify-center{ justify-content: center; }
  .md\:u-justify-end { justify-content: flex-end; }
}

@include mq($from: lg) {
  .lg\:u-flex-1{ flex: 1; }
}
