.c-modal {

}

.c-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.c-modal__container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin: 0;
  max-width: 40rem;
  max-height: calc(100vh - 1rem);
  overflow: visible;
  border-radius: 4px;
  width: calc(100vw - 1rem);

  .c-modal--lg & {
    max-width: 50rem;
  }
}

.c-modal__header,
.c-modal__content,
.c-modal__footer {
  position: relative;
}

.c-modal__header {
  padding: 1rem;
}

.c-modal__content {
  flex: 1;
  overflow: auto;
}

.c-modal__title {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.2;
}

.c-modal__subhead {
  color: $grey-dark;
  margin-top: .75rem;
}

.c-modal__close {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 1.25rem;
  height: 3rem;
  line-height: 1;
  margin-bottom: 1rem;
  margin-left: auto;
  padding: 0;
  width: 3rem;

  &:focus {
    outline: 3px auto $teal-300;
    color: $teal-300;
  }

  &::before {
    content: "\2715";
  }
}

.c-modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0,0,0,.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out;
}

.c-modal__btn:focus, .c-modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.c-modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

.c-modal--action {
  .c-modal__container {
    border-top: 2px solid $red;
  }

  .c-modal__header,
  .c-modal__content {
    padding: 2.074em 1em;
  }

  .c-modal__content {
    background: $grey-100;
    border-top: 1px solid $grey-lighter;
  }
}



/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .c-modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .c-modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .c-modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .c-modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .c-modal__container,
.micromodal-slide .c-modal__overlay {
  will-change: transform;
}
