.c-form__group {
  display: flex;
  flex-direction: column;
  margin-bottom: .75em;
  position: relative;
}

.c-form__footer {
  margin-top: $global-spacing-unit;
}

.c-form__check {
  display: flex;
  flex-direction: row;

  .c-form__label {
    padding-left: 4px;
  }
}

.c-form__label--required::after {
  color: $orange;
  content: ' *';
}

.c-form__control {
  display: block;
  font-weight: 400;
  width: 100%;

  &:focus {
    border-bottom-color: inherit;
    outline: none;

    & + .c-form__label {
      color: #000;
    }
  }
}

.c-form__check-input {
  margin-right: 8px;
  margin-top: 3px;
}

.c-form__help {
  background: $grey-lighter;
  color: $grey-dark;
  font-size: .75em;
  text-transform: uppercase;
}

.c-form__errors {
  font-size: .875rem;
  color: $orange;
  padding: .25rem 0;
}

.c-form--float-label {
  .c-form__label {
    color: $grey-600;
    font-size: .75rem;
    left: 0;
    line-height: 1;
    margin-bottom: .5em;
    order: -1;
    position: relative;

    svg {
      display: block;
    }
  }

  .c-form__label {
    opacity: 0;
    position: absolute;
    top: 5px;
    transition: top 750ms ease-in, opacity 750ms ease-in;
  }

  .c-form__group {
    &:focus-within {
      .c-form__label {
        color: $grey-600;
        opacity: 1;
        top: 0px;
      }
    }
  }

  .c-form__control {
    border: none;
    border-bottom: 1px solid $grey-light;
    border-radius: 0;
    height: 3.5em;
    padding: 0;
    transition: padding-top 125ms ease-in, height 250ms ease-in;

    &:focus-within::placeholder {
      opacity: 0;
    }
  }

  input.c-form__control,
  textarea.c-form__control {
    &:not(:placeholder-shown),
    &:focus-within {
      padding-top: .5em;
    }
  }

  textarea.c-form__control {
    margin-top: .75rem;
  }

  &.c-form--islands {
    .c-form__control {
      background: #fff;
      border: none;
      border-radius: .25rem;
      box-shadow: 0 2px 4px rgba(0,0,0,.1);
      padding-left: 1rem;
      padding-right: 1rem;

      &:not(:placeholder-shown) {
        + .c-form__label {
          top: .375rem;
        }
      }
    }

    .c-form__label {
      left: 1rem;
    }
  }
}

textarea.c-form__control:not(:placeholder-shown) {
  height: 6em;
}

.c-form--search {
  .c-form__group {
    margin: 0;
  }

  .c-form__label {
    height: 1em;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
  }

  .c-form__control {
    text-indent: calc(10px + 1em)
  }
}

.c-form__label--no-float {
  opacity: 0;
}
