.c-montage-hero {
  display: grid;
  grid-template-areas:
    "figure"
    "text";
}

.c-montage-hero__figure {
  grid-area: figure;
}

.c-montage-hero__figure,
.c-montage-hero__figure-secondary {
  img {
    height: 100%;
    max-height: 650px;
    object-fit: cover;
    width: 100%;
  }
}

.c-montage-hero__text {
  grid-area: text;
}

.c-montage-hero__figure-secondary {
  display: none;
  z-index: 1;
}

.c-montage-hero__image {
  flex: 1;
}

@include mq($from: md) {
  .c-montage-hero {
    grid-template-columns: 50% 1fr;
    grid-template-rows: 1fr min-content;
  }

  .c-montage-hero--flip {
    .c-montage-hero__text {
      grid-area: 2 / 1 / 3 / 2;
    }

    .c-montage-hero__figure-secondary {
      grid-area: 1 / 1 / 2 / 2;

      ~ .c-montage-hero__figure {
        grid-area: 1 / 2 / 3 / 3;
      }
    }
  }

  .c-montage-hero__figure {
    grid-area: 1 / 1 / 3 / 3;
  }

  .c-montage-hero__text {
    grid-area: 2 / 2 / 3 / 3;
  }

  .c-montage-hero__figure-secondary {
    display: flex;
    grid-area: 1 / 2;

    ~ .c-montage-hero__figure {
      grid-area: 1 / 1 / 3 / 2;
    }
  }
}
